import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { FaPlusSquare } from "react-icons/fa";
import { supplimentaryServices } from "../../../../APIServices/Supplementary/sdtmServices";
import { cdiscServices } from "../../../../APIServices/cdiscServices";
import Swal from "sweetalert2";

const AddStudySupplementary = ({
  getSupplimentalsList,
  studySupplementaryData,
  setstudySupplementaryData,
  versionId
}) => {
  let [sdtmClass, setSDTMClass] = useState([]);
  let [order, setOrder] = useState("");
  let [classId, setClassId] = useState("");
  let [domain, setDomain] = useState([]);
  let [domainId, setDomainId] = useState("");
  let [variableName, setVariableName] = useState("");
  let [variableLabel, setVariableLabel] = useState("");
  let [variableType, setVariableType] = useState("");
  let [variableComment, setVariableComment] = useState([]);
  let [significant, setSignificant] = useState("");
  let [mandatory, setMandatory] = useState("");
  let [commentId, setCommentId] = useState("");
  let [variableMethod, setVariableMethod] = useState([]);
  let [codeListData, setCodeListData] = useState([]);
  let [methodId, setMethodId] = useState("");
  let [origin, setOrigin] = useState("");
  let [note, setNote] = useState("");
  let [codeList, setCodeList] = useState("");

  const currentUrl = window.location.href;
  const splitUrl = currentUrl?.split("/");
  const studyId = splitUrl[4];

  const addNewSupplimental = async () => {
    let userData = {};
    userData.studyId = studyId;
    userData.domainId = domainId;
    let data = await supplimentaryServices.addNewStudySupplimental(userData);
    if (data?.statusCode === 200) {
      getSupplimentalsList();
    }
  };

  const updateSupplimentals = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Update this Suppliments?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {};
        userData.id = studySupplementaryData._id;
        userData.order = order;
        userData.name = variableName;
        userData.label = variableLabel;
        userData.type = variableType;
        userData.mandatory = mandatory;
        userData.significant = significant;
        userData.commentId = commentId;
        userData.mathodId = methodId;
        userData.origin = origin;
        userData.note = note;
        userData.codeList = codeList;
        let data = await supplimentaryServices.updateStudySupplimentals(
          userData
        );
        if (data?.statusCode === 200) {
          getSupplimentalsList();
          setstudySupplementaryData("");
        }
      }
    });
  };

  useEffect(() => {
    setClassId(studySupplementaryData?.classId);
    setDomainId(studySupplementaryData?.domainId);
    setOrder(studySupplementaryData?.order);
    setVariableName(studySupplementaryData?.name);
    setVariableLabel(studySupplementaryData?.label);
    setVariableType(studySupplementaryData?.dataType);
    setSignificant(studySupplementaryData?.significant);
    setMandatory(studySupplementaryData?.mandatory);
    setCommentId(studySupplementaryData?.commentId);
    setMethodId(studySupplementaryData?.mathodId);
    setOrigin(studySupplementaryData?.origin);
    setNote(studySupplementaryData?.note);
    setCodeList(studySupplementaryData?.codeList);
  }, [studySupplementaryData]);

  const getActiveClass = async (versionId) => {
    let data = await supplimentaryServices.getActiveClass(versionId);
    setSDTMClass(data?.data);
  };

  const getActiveDomains = async (classId) => {
    let data = await supplimentaryServices.getActiveDomains(classId);
    setDomain(data?.data);
  };

  const getSupplimentalCommentsList = async () => {
    let data = await cdiscServices.getSupplimentalCommentsList();
    setVariableComment(data?.data);
  };

  const getSupplimentalMathodsList = async () => {
    let data = await cdiscServices.getSupplimentalMathodsList();
    setVariableMethod(data?.data);
  };

  const getSTDMCodeList = async () => {
    let data = await supplimentaryServices.getClientLevelCodeList();
    setCodeListData(data?.data);
  };

  useEffect(() => {
    getSupplimentalMathodsList();
    getSupplimentalCommentsList();
    getSTDMCodeList();
  }, []);

  useEffect(() => {
    getActiveClass(versionId);
  }, [versionId]);

  const clearFormData = () => {
    setstudySupplementaryData("");
    setClassId("");
    setDomainId("");
    setOrder("");
    setVariableName("");
    setVariableLabel("");
    setVariableType("");
    setSignificant("");
    setMandatory("");
    setCommentId("");
    setMethodId("");
    setOrigin("");
    setNote("");
    setCodeList("");
  };

  return (
    <Container fluid>
      <Row>
        {studySupplementaryData && (
          <Form.Group as={Col} md="12" className="mb-2 p-0">
            <div className="hstack justify-content-between">
              <Form.Label className="fs-12 fw-bold mb-1">
                Order <span className="text-danger">*</span>
              </Form.Label>
              {studySupplementaryData && (
                <button
                  className="fs10 br3 text-white border-primary bg-primary border-0"
                  title="Add new Supplimentary"
                  onClick={() => clearFormData()}
                >
                  <FaPlusSquare style={{ marginBottom: 2 }} /> Add New
                </button>
              )}
            </div>
            <Form.Control
              required
              type="number"
              value={order}
              style={{ fontSize: 11 }}
              onChange={(e) => setOrder(e.target.value)}
              placeholder="Enter Order No."
            />
          </Form.Group>
        )}
        {!studySupplementaryData && (
          <>
            <Form.Group as={Col} md="12" className="mb-2 p-0">
              <Form.Label className="fs-12 fw-bold mb-1">
                Class <span className="text-danger">*</span>
              </Form.Label>
              <Form.Select
                style={{ fontSize: 11 }}
                onChange={(e) => {
                  setClassId(e.target.value);
                  getActiveDomains(e.target.value);
                }}
                value={classId}
              >
                <option value="">Select Class</option>
                {sdtmClass?.map((item, index) => (
                  <option
                    selected={item.classId === item._id}
                    key={index}
                    value={item._id}
                  >
                    {item.class}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group as={Col} md="12" className="mb-2 p-0">
              <Form.Label className="fs-12 fw-bold mb-1">
                Domain <span className="text-danger">*</span>
              </Form.Label>
              <Form.Select
                style={{ fontSize: 11 }}
                onChange={(e) => setDomainId(e.target.value)}
                value={domainId}
              >
                <option value="">Select Domain</option>
                {domain?.map((item, index) => (
                  <option
                    selected={item.domainId === item._id}
                    key={index}
                    value={item._id}
                  >
                    {item.domain}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </>
        )}
        {studySupplementaryData && (
          <>
            <Form.Group as={Col} md="12" className="mb-2 p-0">
              <Form.Label className="fs-12 fw-bold mb-1">
                Veriable Name <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                required
                type="text"
                value={variableName}
                style={{ fontSize: 11 }}
                onChange={(e) => setVariableName(e.target.value)}
                placeholder="Enter Veriable Name"
              />
            </Form.Group>
            <Form.Group as={Col} md="12" className="mb-2 p-0">
              <Form.Label className="fs-12 fw-bold mb-1">
                Veriable Label <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                required
                type="text"
                value={variableLabel}
                style={{ fontSize: 11 }}
                onChange={(e) => setVariableLabel(e.target.value)}
                placeholder="Enter Veriable Name"
              />
            </Form.Group>
            <Form.Group as={Col} md="12" className="mb-2 p-0">
              <Form.Label className="fs-12 fw-bold mb-1">
                Data Type <span className="text-danger">*</span>
              </Form.Label>
              <Form.Select
                style={{ fontSize: 11 }}
                onChange={(e) => setVariableType(e.target.value)}
              >
                <option value="">Select Type</option>
                <option
                  selected={variableType === "text" && "text"}
                  value="text"
                >
                  Text
                </option>
                <option
                  selected={variableType === "integer" && "integer"}
                  value="integer"
                >
                  Integer
                </option>
                <option
                  selected={variableType === "float" && "float"}
                  value="float"
                >
                  Float
                </option>
              </Form.Select>
            </Form.Group>
            {variableType === "float" && (
              <Form.Group as={Col} md="12" className="mb-2 p-0">
                <Form.Label className="fs-12 fw-bold mb-1">
                  Significant <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  required
                  type="number"
                  value={significant}
                  style={{ fontSize: 11 }}
                  onChange={(e) => setSignificant(e.target.value)}
                  placeholder="Enter Veriable Name"
                />
              </Form.Group>
            )}
            <Form.Group as={Col} md="12" className="mb-2 p-0">
              <Form.Label className="fs-12 fw-bold mb-1">
                Mandatory <span className="text-danger">*</span>
              </Form.Label>
              <Form.Select
                style={{ fontSize: 11 }}
                onChange={(e) => setMandatory(e.target.value)}
              >
                <option value="">Select Type</option>
                <option selected={mandatory === "yes" && "yes"} value="yes">
                  Yes
                </option>
                <option selected={mandatory === "no" && "no"} value="no">
                  No
                </option>
              </Form.Select>
            </Form.Group>
            <Form.Group as={Col} md="12" className="mb-2 p-0">
              <Form.Label className="fs-12 fw-bold mb-1">
                Origin <span className="text-danger">*</span>
              </Form.Label>
              <Form.Select
                style={{ fontSize: 11 }}
                onChange={(e) => setOrigin(e.target.value)}
              >
                <option value="">Select Origin</option>
                <option
                  selected={origin === "Protocol" && "Protocol"}
                  value="Protocol"
                >
                  Protocol
                </option>
                <option
                  selected={origin === "Assigned" && "Assigned"}
                  value="Assigned"
                >
                  Assigned
                </option>
                <option
                  selected={origin === "Derived" && "Derived"}
                  value="Derived"
                >
                  Derived
                </option>
                <option selected={origin === "CRF" && "CRF"} value="CRF">
                  CRF
                </option>
                <option selected={origin === "eDT" && "eDT"} value="eDT">
                  eDT
                </option>
              </Form.Select>
            </Form.Group>
            <Form.Group as={Col} md="12" className="mb-2 p-0">
              <Form.Label className="fs-12 fw-bold mb-1">
                Variable Comment
              </Form.Label>
              <Form.Select
                style={{ fontSize: 11 }}
                onChange={(e) => {
                  setCommentId(e.target.value);
                }}
                value={commentId}
                disabled={methodId}
              >
                <option value="">Select Variable Comment</option>
                {variableComment?.map((item, index) => (
                  <option
                    selected={item.commentId === item._id}
                    key={index}
                    value={item._id}
                  >
                    {item.commentId}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group as={Col} md="12" className="mb-2 p-0">
              <Form.Label className="fs-12 fw-bold mb-1">
                Variable Method
              </Form.Label>
              <Form.Select
                style={{ fontSize: 11 }}
                onChange={(e) => setMethodId(e.target.value)}
                value={methodId}
                disabled={commentId}
              >
                <option value="">Select Variable Method</option>
                {variableMethod?.map((item, index) => (
                  <option
                    selected={item.mathodId === item._id}
                    key={index}
                    value={item._id}
                  >
                    {item.mathodId}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group as={Col} md="12" className="mb-2 p-0">
              <Form.Label className="fs-12 fw-bold mb-1">
                Code List <span className="text-danger">*</span>
              </Form.Label>
              <Form.Select
                style={{ fontSize: 11 }}
                onChange={(e) => setCodeList(e.target.value)}
                value={codeList}
              >
                <option value="">Select Code List</option>
                {codeListData?.map((item, index) => (
                  <option key={index} value={item?.codeList}>
                    {item?.codeId}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group as={Col} md="12" className="mb-2 p-0">
              <Form.Label className="fs-12 fw-bold mb-1">
                CDISC Note <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                required
                as="textarea"
                value={note}
                style={{ fontSize: 11 }}
                onChange={(e) => setNote(e.target.value)}
                placeholder="Enter Veriable Name"
              />
            </Form.Group>
          </>
        )}
      </Row>
      <Form.Group className="text-end">
        <button
          className="p-1 px-3 border rounded-1 text-white bg-color fs-13 text-capitalize"
          onClick={() =>
            studySupplementaryData
              ? updateSupplimentals()
              : addNewSupplimental()
          }
        >
          <FaPlusSquare style={{ marginBottom: 2 }} />{" "}
          {!studySupplementaryData ? "Add" : "Update"} Supplementary
        </button>
      </Form.Group>
    </Container>
  );
};

export default AddStudySupplementary;
