import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
// import { sdtmServices } from "../../../../APIServices/CDISC/sdtmServices";
import { FaPlusSquare } from "react-icons/fa";
import { supplimentaryServices } from "../../../../APIServices/Supplementary/sdtmServices";

const AddCodeListForm = ({
  getSTDMCodeData,
  currentTabId,
  updateCodeList,
  setUpdateCodeList,
}) => {
  let STDMId = currentTabId();
  let [type, setType] = useState("");
  let [name, setName] = useState("");
  let [codeId, setCodeId] = useState("");
  let [dataType, setDataType] = useState("");
  let [terms, setTerms] = useState("");
  let [order, setOrder] = useState("");
  let [decodeValue, setDecodeValue] = useState("");
  let [loader, setLoader] = useState(false);
  let [codeList, setCodeList] = useState([]);

  const [fields, setFields] = useState([
    { terms: "", order: "", decodeValue: "" },
  ]);

  const handleInputChange = (index, event) => {
    const values = [...fields];
    values[index][event.target.name] = event.target.value;
    setFields(values);
  };

  const handleAddField = () => {
    setFields([...fields, { terms: "", order: "", decodeValue: "" }]);
  };

  const handleRemoveField = (index) => {
    const values = [...fields];
    values.splice(index, 1);
    setFields(values);
  };

  const addNewClientCodeList = async () => {
    setLoader(true);
    let userData = {};
    userData.stdmId = STDMId;
    userData.type = type;
    userData.codeId = codeId;
    if (type === "custom") {
      userData.codeName = name;
      userData.dataType = dataType;
    }
    if (type === "custom") {
      userData.termsData = fields;
    }
    let data = await supplimentaryServices.addNewClientCodeList(userData);
    if (data?.statusCode === 200) {
      cleanInputBox();
      getSTDMCodeData(STDMId);
    }
    setLoader(false);
  };

  const getSTDMTerminologyCodeList = async (STDMId) => {
    let data = await supplimentaryServices.getSTDMTerminologyCodeList(STDMId);
    setCodeList(data?.data);
  };

  useEffect(() => {
    getSTDMTerminologyCodeList(STDMId);
  }, [STDMId]);

  useEffect(() => {
    setType(updateCodeList?.type);
    setName(updateCodeList?.codeName);
    setCodeId(updateCodeList?.codeId);
    setDataType(updateCodeList?.dataType);
    setDecodeValue(updateCodeList?.decodeValue);
    setOrder(updateCodeList?.order);
    setTerms(updateCodeList?.terms);
  }, [updateCodeList]);

  const cleanInputBox = () => {
    setType("");
    setName("");
    setCodeId("");
    setDataType("");
    setFields([{ terms: "", order: "", decodeValue: "" }]);
  };

  const updateSTDMCodeData = async () => {
    let userData = {};
    userData.id = updateCodeList?._id;
    userData.order = order;
    if (type === "custom") {
      userData.codeId = codeId;
      userData.terms = terms;
      userData.codeName = name;
      userData.dataType = dataType;
      userData.decodeValue = decodeValue;
    }
    let data = await supplimentaryServices.updateClientCodeList(userData);
    if (data?.statusCode === 200) {
      getSTDMCodeData(STDMId);
      setUpdateCodeList("");
    }
  };

  const clearFormData = () => {
    setUpdateCodeList("");
    setType("");
    setName("");
    setCodeId("");
    setDataType("");
    setDecodeValue("");
    setOrder("");
    setTerms("");
  };

  return (
    <div>
      {updateCodeList && (
        <Form.Group className="mb-2">
          <div className="hstack justify-content-between">
            <Form.Label className="fw-bold mb-1">
              Order <span>*</span>
            </Form.Label>
            {updateCodeList && (
              <button
                className="fs10 br3 text-white border-primary bg-primary border-0"
                title="Add new Codelist"
                onClick={() => clearFormData()}
              >
                <FaPlusSquare style={{ marginBottom: 2 }} /> Add New
              </button>
            )}
          </div>
          <Form.Control
            type="number"
            placeholder="Enter Code Name"
            value={order}
            onChange={(e) => setOrder(e.target.value)}
          />
        </Form.Group>
      )}
      <Form.Group className="mb-2">
        <Form.Label className="fw-bold mb-1">
          Code List Type <span>*</span>
        </Form.Label>
        <Form.Select
          style={{ fontSize: 11 }}
          onChange={(e) => setType(e.target.value)}
          aria-label="Default select example"
          disabled={updateCodeList}
        >
          <option>Select Code List Type</option>
          <option selected={type === "standard" && "standard"} value="standard">
            Standard
          </option>
          <option selected={type === "custom" && "custom"} value="custom">
            Custom
          </option>
        </Form.Select>
      </Form.Group>
      {type === "standard" && !updateCodeList && (
        <Form.Group className="mb-2">
          <Form.Label className="fw-bold mb-1">
            Code Id <span>*</span>
          </Form.Label>
          <Form.Select
            style={{ fontSize: 11 }}
            onChange={(e) => {
              setCodeId(e.target.value);
            }}
            aria-label="Default select example"
          >
            <option>Select Code Id</option>
            {codeList?.map((item, index) => (
              <option
                title={item?.parent_extensible}
                key={index}
                selected={item?.parent_id === codeId}
                value={item?.parent_id}
              >
                {item?.parent_id}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      )}
      {(type === "custom" || updateCodeList) && (
        <>
          <Form.Group className="mb-2">
            <Form.Label className="fw-bold mb-1">
              Code Id <span>*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Code Id"
              value={codeId}
              style={{ fontSize: 11 }}
              disabled={type === "standard"}
              onChange={(e) => setCodeId(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label className="fw-bold mb-1">
              Code Name <span>*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Code Name"
              value={name}
              style={{ fontSize: 11 }}
              disabled={type === "standard"}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label className="fw-bold mb-1">
              Data Type <span>*</span>
            </Form.Label>
            <Form.Select
              style={{ fontSize: 11 }}
              disabled={type === "standard"}
              onChange={(e) => setDataType(e.target.value)}
              aria-label="Default select example"
            >
              <option>Select Data Type</option>
              <option selected={dataType === "text"} value={"text"}>
                Text
              </option>
              <option selected={dataType === "float"} value={"float"}>
                Float
              </option>
              <option selected={dataType === "integer"} value={"integer"}>
                Integer
              </option>
            </Form.Select>
          </Form.Group>
        </>
      )}
      {type === "custom" && (
        <Form.Group className="mb-2">
          {!updateCodeList && (
            <>
              <div className="hstack justify-content-between">
                <Form.Label className="fw-bold mb-1">
                  Terms <span>*</span>
                </Form.Label>
                <button
                  className="w-auto p-0 border-0 bg-transparent"
                  style={{ fontSize: 12 }}
                  onClick={handleAddField}
                >
                  <i className="fa fa-square-plus"></i> Add More
                </button>
              </div>
              {fields.map((field, index) => (
                <div className="hstack gap-2 mb-2" key={index}>
                  <Form.Control
                    type="text"
                    placeholder="Enter Terms Type"
                    name="terms"
                    value={field.terms}
                    style={{ fontSize: 11 }}
                    onChange={(e) => handleInputChange(index, e)}
                  />
                  <Form.Control
                    type="text"
                    placeholder="Enter Order"
                    name="order"
                    value={field.order}
                    style={{ fontSize: 11, width: 100 }}
                    onChange={(e) => handleInputChange(index, e)}
                  />
                  <Form.Control
                    type="text"
                    placeholder="Enter Decode Value"
                    name="decodeValue"
                    value={field.decodeValue}
                    style={{ fontSize: 11 }}
                    onChange={(e) => handleInputChange(index, e)}
                  />
                  {fields.length > 1 && (
                    <button
                      className="w-auto p-0 text-danger border-0 bg-transparent"
                      title="Delete this Row"
                      style={{ fontSize: 12 }}
                      onClick={() => handleRemoveField(index)}
                    >
                      <i className="fa fa-trash"></i>
                    </button>
                  )}
                </div>
              ))}
            </>
          )}
        </Form.Group>
      )}
      {updateCodeList && (
        <>
          <Form.Group className="mb-2">
            <Form.Label className="fw-bold mb-1">
              Terms <span>*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Terms"
              style={{ fontSize: 11 }}
              value={terms}
              disabled={type === "standard"}
              onChange={(e) => setTerms(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label className="fw-bold mb-1">
              Decode Value <span>*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Decode Value"
              style={{ fontSize: 11 }}
              value={decodeValue}
              disabled={type === "standard"}
              onChange={(e) => setDecodeValue(e.target.value)}
            />
          </Form.Group>
        </>
      )}
      <Form.Group className="text-end">
        <button
          className="p-1 px-3 border rounded-1 text-white bg-color fs-13 text-capitalize"
          onClick={() => {
            updateCodeList ? updateSTDMCodeData() : addNewClientCodeList();
          }}
        >
          <FaPlusSquare style={{ marginBottom: 2 }} />{" "}
          {!updateCodeList ? "Add" : "Update"} Code List
        </button>
      </Form.Group>
    </div>
  );
};

export default AddCodeListForm;
