import config from "../config/config.json";
import axios from "axios";
import { headers } from "../APIHelpers/Headers";

import {
  handleResponse,
  headersWithAuth,
  headersWithAuthWithoutBody,
} from "../APIHelpers/Responses";

export const authServices = {
  login,
  logout,
  forgotPassword,
  resetPassword,
  otpVerification,
  changePassword,
  twoFAEnableDisable,
  updateProfileImage,
  getCurrectLoginUser,
  updateProfile,
  generateQRCode,
  getQRData,
  showQRCode,
  enableTwoFactorAuth,
  disableTwoFactorAuth,
  twoFactorAuthType,
  getTwoFactorAuthType,
  emailOTPFor2FA,
  enableTwoFactorAuthByEmail,
  disableTwoFactorAuthByEmail,
  verifyTwoFAForEmailLogin,
  verifyTwoFAForAppLogin,
  disableTwoFactorAuthByAdmin,
};

// User login
async function login(userData) {
  const response = await fetch(
    `${config.API_URL}login`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  let roleData = data?.userData?.roleType;
  if (data.statusCode === 200) {
    localStorage.setItem("token", JSON.stringify(data.token));
    localStorage.setItem("currentRole", JSON.stringify(roleData));
    if (roleData === "cdisc") {
      window.location.href = "/client";
    } else {
      let Message = {
        statusCode: 400,
        customMessage: "You have not registered on this panel.",
      };
      return Message;
    }
    return data;
  } else if (data.statusCode === 401) {
    return data;
  } else {
    return data;
  }
}

// User Logout
async function logout(userData) {
  const response = await fetch(
    `${config.API_URL}logout`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  if (data.statusCode === 200) {
    localStorage.clear();
    window.location.href = "/login";
  }
}

// OTP Verification
async function otpVerification(userData) {
  const response = await fetch(
    `${config.API_URL}otpVerification`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  if (data.statusCode === 200) {
    localStorage.setItem("isTwoFA", JSON.stringify(false));
    window.location.href = "/admin/dashboard";
  }
  return data;
}

// Change Password
async function changePassword(userData) {
  const response = await fetch(
    `${config.API_URL}changePassword`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Update Profile Image
async function updateProfileImage(formData, id) {
  const response = await axios.post(
    `${config.API_URL}updateProfileImage/${id}`,
    formData
  );
  const data = response?.data;
  await handleResponse(data);
  return data;
}

// Reset Password
async function resetPassword(userData) {
  const response = await fetch(
    `${config.API_URL}resetPassword`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Forgot Password
async function forgotPassword(userData) {
  const response = await fetch(
    `${config.API_URL}forgotPassword`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Get Current User Login Profile Data
async function getCurrectLoginUser() {
  const response = await fetch(
    `${config.API_URL}getCurrectLoginUser`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Two Factor Authentication
async function twoFAEnableDisable(userData) {
  const response = await fetch(
    `${config.API_URL}twoFAEnableDisable`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Update Company Profile Data
async function updateProfile(userData) {
  const response = await fetch(
    `${config.API_URL}updateProfile`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Generate QR code
async function generateQRCode() {
  const response = await fetch(
    `${config.API_URL}generateQRCode`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get QR Data
async function getQRData() {
  const response = await fetch(
    `${config.API_URL}getQRData`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Show QR code
async function showQRCode() {
  const response = await fetch(
    `${config.API_URL}showQRCode`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Enable Two Factor Auth
async function enableTwoFactorAuth(userData) {
  const response = await fetch(
    `${config.API_URL}enableTwoFactorAuth`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Disable Two Factor Auth
async function disableTwoFactorAuth(userData) {
  const response = await fetch(
    `${config.API_URL}disableTwoFactorAuth`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Get Auth Type
async function getTwoFactorAuthType() {
  const response = await fetch(
    `${config.API_URL}getTwoFactorAuthType`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Update Auth Type
async function twoFactorAuthType(userData) {
  const response = await fetch(
    `${config.API_URL}twoFactorAuthType`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Send OTP for Email two factor auth
async function emailOTPFor2FA(userData) {
  const response = await fetch(
    `${config.API_URL}emailOTPFor2FA`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Disable Email Two Factor Auth
async function disableTwoFactorAuthByEmail(userData) {
  const response = await fetch(
    `${config.API_URL}disableTwoFactorAuthByEmail`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Enable Email Two Factor Auth
async function enableTwoFactorAuthByEmail(userData) {
  const response = await fetch(
    `${config.API_URL}enableTwoFactorAuthByEmail`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Verify Two FA for Email Login
async function verifyTwoFAForEmailLogin(userData) {
  const response = await fetch(
    `${config.API_URL}verifyTwoFAForEmailLogin`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Verify Two FA for App Login
async function verifyTwoFAForAppLogin(userData) {
  const response = await fetch(
    `${config.API_URL}verifyTwoFAForAppLogin`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Disable Two Factor Auth by Admin
async function disableTwoFactorAuthByAdmin(userData) {
  const response = await fetch(
    `${config.API_URL}disableTwoFactorAuthByAdmin`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}
