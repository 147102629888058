import React, { useEffect, useState } from "react";
import AddComments from "./AddComments";
import SplitPane from "react-split-pane";
import { Form, InputGroup, Spinner } from "react-bootstrap";
import { AiOutlinePlus } from "react-icons/ai";
import UseToggle from "../../../../Hooks/UseToggle";
import CommentsTableData from "./CommentsTableData";
import { BiCollapse, BiExpand } from "react-icons/bi";
import { CiFilter, CiViewTable } from "react-icons/ci";
import { TbLayoutSidebarRightExpand } from "react-icons/tb";
import CommentsTableProperties from "./CommentsTableProperties";
import { cdiscServices } from "../../../../APIServices/cdiscServices";
import CommentFilterData from "./CommentFilterData";
import { VscClose } from "react-icons/vsc";

const Comments = ({ onMouseDown, tableRef }) => {
  const [loader, setLoader] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isOpenProperty, setIsOpenProperty] = useState(false);
  const [commentId, setCommentId] = useState("");
  const [searchData, setSearchData] = useState("");
  const [description, setDescription] = useState("");
  const { toggle, setToggle, toggle1, setToggle1 } = UseToggle();
  const [addNewCommentsdata, setAddNewCommentsdata] = useState([]);
  const [selectedCommentsId, setSelectedCommentsId] = useState(null);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  const handleSearch = async (searchValue) => {
    let userData = {};
    userData.searchData = searchValue || searchData;
    let data = await cdiscServices.searchSupplimentalComment(userData);
    setAddNewCommentsdata(data?.data);
  };

  const getSupplimentalCommentsList = async () => {
    let data = await cdiscServices.getSupplimentalCommentsList();
    setAddNewCommentsdata(data?.data);
  };

  const HeaderTools = [
    {
      title: isFormOpen ? "Hide Form" : "Add Comments",
      icon: isFormOpen ? <TbLayoutSidebarRightExpand /> : <AiOutlinePlus />,
      onClick: () => setIsFormOpen(!isFormOpen),
    },
    {
      title: "View Table Properties",
      icon: <CiViewTable />,
      onClick: setToggle,
    },
  ];

  const toggleSearch = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    getSupplimentalCommentsList();
  }, []);

  return (
    <div className={`code-list-container ${isFullScreen ? "full-screen" : ""}`}>
      <div
        className="hstack justify-content-between w-100 border-bottom"
        style={{ padding: "4.6px 9px" }}
      >
        <div className="hstack gap-2">
          {HeaderTools?.map((button, index) => (
            <React.Fragment key={index}>
              <button
                title={button.title}
                className="tabButtons border rounded-1 fs-13"
                style={{ padding: "1px 5px" }}
                onClick={button.onClick}
              >
                {button.icon}
              </button>
              {index < 1 && <div className="vr"></div>}
            </React.Fragment>
          ))}
          <div className="vr"></div>
          <InputGroup>
            <InputGroup.Text className="p-0 border-0">
              <button
                className="tabButtons border fs-13"
                style={{ padding: "1px 5px", borderRadius: "3px 0 0 3px" }}
                onClick={toggleSearch}
              >
                <CiFilter />
              </button>
            </InputGroup.Text>
            <div
              className={`p-0 d-flex gap-0 ${
                isExpanded ? "expand-input" : "collapse-input"
              }`}
              style={{
                maxWidth: isExpanded ? "200px" : "0px",
                transition: "max-width 0.3s ease-out",
                overflow: "hidden",
              }}
            >
              <Form.Control
                className="p-0 rounded-0"
                type="search"
                onChange={(e) => handleSearch(e.target.value)}
              />
              <InputGroup.Text className="p-0 border-0">
                <button
                  className="tabButtons border fs-13"
                  style={{ padding: "1px 5px", borderRadius: "0 3px 3px 0" }}
                >
                  <VscClose />
                </button>
              </InputGroup.Text>
            </div>
          </InputGroup>
        </div>
        <button
          className="tabButtons border rounded-1 fs-13"
          title={!isFullScreen ? "Full Mode" : "Original Mode"}
          onClick={toggleFullScreen}
        >
          {isFullScreen ? <BiCollapse /> : <BiExpand />}
        </button>
      </div>
      <div className="position-relative">
        <SplitPane
          split="horizontal"
          style={{
            height: isFullScreen ? "calc(100vh - 35px)" : "calc(100vh - 148px)",
          }}
          defaultSize={isOpenProperty ? "40%" : "100%"}
        >
          <SplitPane
            split="vertical"
            minSize={20}
            defaultSize={isFormOpen ? "20%" : "100%"}
          >
            {isFormOpen && (
              <AddComments
                setCommentId={setCommentId}
                commentId={commentId}
                setDescription={setDescription}
                description={description}
                setSelectedCommentsId={setSelectedCommentsId}
                selectedCommentsId={selectedCommentsId}
                getSupplimentalCommentsList={getSupplimentalCommentsList}
              />
            )}
            <div>
              {loader ? (
                <div className="hstack justify-content-center h-75">
                  <Spinner />
                </div>
              ) : addNewCommentsdata?.length === 0 ? (
                <div className="hstack justify-content-center h-75">
                  Data not found!
                </div>
              ) : (
                <CommentsTableData
                  setIsFormOpen={setIsFormOpen}
                  setCommentId={setCommentId}
                  setDescription={setDescription}
                  addNewCommentsdata={addNewCommentsdata}
                  setSelectedCommentsId={setSelectedCommentsId}
                  getSupplimentalCommentsList={getSupplimentalCommentsList}
                  onMouseDown={onMouseDown}
                  tableRef={tableRef}
                />
              )}
            </div>
          </SplitPane>
        </SplitPane>
      </div>
      <CommentsTableProperties
        Show={!toggle}
        Hide={setToggle}
        Title={"Table Properties"}
      />
      <CommentFilterData
        Show={!toggle1}
        Hide={setToggle1}
        Title={"Filter Comments"}
      />
    </div>
  );
};

export default Comments;
