import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { CiFilter, CiViewTable } from "react-icons/ci";
import SplitPane from "react-split-pane";
import { BiCollapse, BiExpand } from "react-icons/bi";
import STDMMethodTableProperties from "./STDMMethodTableProperties";
import STDMMethodTableData from "./STDMMethodTableData";
import UseToggle from "../../../../Hooks/UseToggle";
import { cdiscServices } from "../../../../APIServices/cdiscServices";

const STDMMethod = ({ onMouseDown, tableRef }) => {
  const [loader, setLoader] = useState(false);
  const { toggle, setToggle } = UseToggle();
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [stdmMethod, setSTDMMethod] = useState([]);

  const currentUrl = window.location.href;
  const splitUrl = currentUrl?.split("/");
  const studyId = splitUrl[4];


  const getCDISCMathods = async () => {
    setLoader(true);
    let data = await cdiscServices.getCDISCMathods(studyId);
    setSTDMMethod(data?.data || []);
    setLoader(false);
  };


  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };


  useEffect(() => {
    getCDISCMathods();
  }, []);

  const HeaderTools = [
    {
      title: "View Table Properties",
      icon: <CiViewTable />,
      onClick: setToggle,
    },
    { title: "Filter", icon: <CiFilter /> },
  ];

  return (
    <div className={`code-list-container ${isFullScreen ? "full-screen" : ""}`}>
      <div
        className="hstack justify-content-between w-100 border-bottom"
        style={{ padding: "4.6px 9px" }}
      >
        <div className="hstack gap-2">
          {HeaderTools?.map((button, index) => (
            <React.Fragment key={index}>
              <button
                title={button.title}
                className="tabButtons border rounded-1 fs-13"
                style={{ padding: "1px 5px" }}
                onClick={button.onClick}
              >
                {button.icon}
              </button>
              {index < 1 && <div className="vr"></div>}
            </React.Fragment>
          ))}
        </div>
        <button
          className="tabButtons border rounded-1 fs-13"
          title={!isFullScreen ? "Full Mode" : "Original Mode"}
          onClick={toggleFullScreen}
        >
          {isFullScreen ? <BiCollapse /> : <BiExpand />}
        </button>
      </div>
      <div className="position-relative">
        <SplitPane
          split="horizontal"
          style={{
            height: isFullScreen ? "calc(100vh - 35px)" : "calc(100vh - 148px)",
          }}
          defaultSize={"100%"}
        >
          <SplitPane split="vertical" minSize={20} defaultSize={"100%"}>
            <>
              {loader ? (
                <div className="hstack justify-content-center h-100 w-100">
                  <Spinner />
                </div>
              ) : stdmMethod?.length === 0 ? (
                <div className="hstack justify-content-center h-100 w-100">
                  Data not found!
                </div>
              ) : (
                <STDMMethodTableData
                  stdmMethod={stdmMethod}
                  onMouseDown={onMouseDown}
                  tableRef={tableRef}
                  getCDISCMathods={getCDISCMathods}
                />
              )}
            </>
          </SplitPane>
        </SplitPane>
      </div>
      <STDMMethodTableProperties
        Show={!toggle}
        Hide={setToggle}
        Title={"Table Properties"}
      />
    </div>
  );
};

export default STDMMethod;
