import React, { useState } from "react";
import { Alert, Card, Form, Spinner } from "react-bootstrap";
import { authServices } from "../../APIServices/authServices";
import { NavLink, useLocation } from "react-router-dom";
import { TfiBackLeft } from "react-icons/tfi";

const ResetPassword = () => {
  const search = useLocation().search;
  const token = new URLSearchParams(search).get("token");

  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prevState) => !prevState);
  };

  const [newPassword, setNewPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [loader, setLoader] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");

  const resetPassword = async () => {
    setLoader(true);
    let userData = {};
    userData.token = token;
    userData.newPassword = newPassword;
    userData.repeatPassword = repeatPassword;
    let data = await authServices.resetPassword(userData);
    setLoader(false);
    setStatusMessage(data);
    if (data?.statusCode === 200) {
      resetInputField();
      window.location.href = "/";
    }
    setTimeout(() => {
      setStatusMessage("");
    }, 3000);
  };

  const [error, setError] = useState("");

  const validatePassword = (input) => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{};:,<.>]).{8,}$/;

    if (!passwordRegex.test(input)) {
      setError(
        "Password must contain at least one lowercase letter, one uppercase letter, one digit, one special character, and be at least 8 characters long."
      );
    } else {
      setError("");
    }
  };

  const resetInputField = () => {
    setNewPassword("");
    setRepeatPassword("");
  };

  return (
    <>
      <div
        className="w-100 hstack justify-content-center"
        style={{ height: "100vh" }}
      >
        <Card
          style={{ width: "30rem" }}
          className="border rounded-1 shadow-sm p-3"
        >
          <Card.Body>
            <Card.Title className="fw-bold text-center">
              Reset Password
            </Card.Title>
            <Card.Text>
              <Form.Group className="mb-2">
                <Form.Label className="mb-1 fw-bold">
                  New Password <span className="text-danger">*</span>
                </Form.Label>
                <div className="position-relative">
                  <Form.Control
                    required
                    type={showPassword ? "text" : "password"}
                    className={
                      (error && "border-danger text-danger") ||
                      (newPassword && "border-success")
                    }
                    placeholder="Enter New Password"
                    onChange={(e) => {
                      setNewPassword(e.target.value);
                      validatePassword(e.target.value);
                    }}
                    value={newPassword}
                  />
                  {newPassword && (
                    <>
                      {error ? (
                        <Form.Text className="fs-10 text-danger fw-bold">
                          Password Must have 1 Capital Letter, 1 Small Letter, 1
                          Special Character with 8 Character
                        </Form.Text>
                      ) : (
                        <Form.Text className="fs-10 text-success fw-bold">
                          Strong Password
                        </Form.Text>
                      )}
                    </>
                  )}
                  <div
                    className="passwordvisiability"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? (
                      <i className="fa-regular fa-eye-slash fs-13"></i>
                    ) : (
                      <i className="fa-regular fa-eye fs-13"></i>
                    )}
                  </div>
                </div>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="mb-1 fw-bold">
                  Confirm Password <span className="text-danger">*</span>
                </Form.Label>
                <div className="position-relative">
                  <Form.Control
                    required
                    type={showConfirmPassword ? "text" : "password"}
                    className={
                      (newPassword !== repeatPassword &&
                        "border-danger text-danger") ||
                      (repeatPassword && "border-success")
                    }
                    placeholder="Enter Password"
                    onChange={(e) => setRepeatPassword(e.target.value)}
                    value={repeatPassword}
                  />
                  <div
                    className="passwordvisiability"
                    onClick={toggleConfirmPasswordVisibility}
                  >
                    {showConfirmPassword ? (
                      <i className="fa-regular fa-eye-slash fs-13"></i>
                    ) : (
                      <i className="fa-regular fa-eye fs-13"></i>
                    )}
                  </div>
                  {repeatPassword && (
                    <>
                      {newPassword === repeatPassword ? (
                        <Form.Text className="fs-10 text-success fw-bold">
                          Password Match
                        </Form.Text>
                      ) : (
                        <Form.Text className="fs-10 text-danger fw-bold">
                          Password Not Match
                        </Form.Text>
                      )}
                    </>
                  )}
                </div>
              </Form.Group>
              <Form.Group className="mb-2">
                <button
                  className={`w-100 hstack gap-1 justify-content-center tabButtons border rounded-1 fs-13 p-1`}
                  disabled={loader || error || !newPassword || !repeatPassword}
                  onClick={resetPassword}
                >
                  <div className="fw-bold">
                    {loader && <Spinner style={{ width: 14, height: 14 }} />}{" "}
                    Reset Password
                  </div>
                </button>
              </Form.Group>
              <Form.Group className="mb-2 text-end">
                <NavLink
                  to={"/login"}
                  className="fs-13 text-dark"
                  title="Click for Login panel"
                >
                  Back to Login <TfiBackLeft />
                </NavLink>
              </Form.Group>
              <Form.Group>
                {statusMessage && (
                  <Alert
                    className="fs-10 py-1 px-1 m-0"
                    variant={
                      statusMessage?.statusCode === 200 ? "success" : "danger"
                    }
                  >
                    {statusMessage?.customMessage}
                  </Alert>
                )}
                </Form.Group>
            </Card.Text>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default ResetPassword;
