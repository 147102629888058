import React, { useEffect, useState } from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import { FaUsers } from "react-icons/fa";

const Dashboard = ({ userCurrentData, tabs }) => {
  const [greeting, setGreeting] = useState("");

  const getGreeting = () => {
    const currentTime = new Date().getHours();
    let greeting;

    if (currentTime >= 5 && currentTime < 12) {
      greeting = "Good Morning";
    } else if (currentTime >= 12 && currentTime < 18) {
      greeting = "Good Afternoon";
    } else {
      greeting = "Good Evening";
    }
    setGreeting(greeting);
  };

  useEffect(() => {
    getGreeting();
  }, []);
  return (
    <>
      <div className="border-bottom">
        <div className="text-center py-2">
          <h5 className="m-0">
            {greeting}, {userCurrentData?.fullName}
          </h5>
          <Form.Label className="m-0">
            Welcome to Your CDISC Study Dashboard.
          </Form.Label>
        </div>
      </div>
      <div
        className="overflow-auto py-2"
        style={{
          height:
            tabs?.length > 0 ? "calc(100vh - 180px)" : "calc(100vh - 145px)",
        }}
      >
        <Container fluid>
          <Row className="chart__overview">
            <Form.Group as={Col} md={3} sm={12}>
              <Card className="mb-3 bg-light border">
                <Card.Body>
                  <div className="d-flex gap-3">
                    <div className="study_icopn">
                      <FaUsers className="rounded-1 bg-white shadow-sm " />
                    </div>
                    <div>
                      <Card.Title>Active Clients</Card.Title>
                      <Card.Text className="m-0">45</Card.Text>
                      <Card.Text>new client added in past 1 month.</Card.Text>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Form.Group>
            <Form.Group as={Col} md={3} sm={12}>
              <Card className="mb-3 bg-light border">
                <Card.Body>
                  <div className="d-flex gap-3">
                    <div className="study_icopn">
                      <FaUsers className="rounded-1 bg-white shadow-sm " />
                    </div>
                    <div>
                      <Card.Title>Active Study</Card.Title>
                      <Card.Text className="m-0">45</Card.Text>
                      <Card.Text>new client added in past 1 month.</Card.Text>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Form.Group>
            <Form.Group as={Col} md={3} sm={12}>
              <Card className="mb-3 bg-light border">
                <Card.Body>
                  <div className="d-flex gap-3">
                    <div className="study_icopn">
                      <FaUsers className="rounded-1 bg-white shadow-sm " />
                    </div>
                    <div>
                      <Card.Title>Active Project</Card.Title>
                      <Card.Text className="m-0">45</Card.Text>
                      <Card.Text>new client added in past 1 month.</Card.Text>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Form.Group>
            <Form.Group as={Col} md={3} sm={12}>
              <Card className="mb-3 bg-light border">
                <Card.Body>
                  <div className="d-flex gap-3">
                    <div className="study_icopn">
                      <FaUsers className="rounded-1 bg-white shadow-sm " />
                    </div>
                    <div>
                      <Card.Title>Active Project</Card.Title>
                      <Card.Text className="m-0">45</Card.Text>
                      <Card.Text>new client added in past 1 month.</Card.Text>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Form.Group>
            {/* <Form.Group md={8} sm={12}>
              <Card className="mb-3 bg-light border">
                <Card.Body>
                  <Card.Title>Recent activity</Card.Title>
                  <Card.Text className="m-0 mt-2">
                    <div style={{ height: 240, overflow: "auto" }}>
                      {recentActivities?.map(({ date, data }, index) => (
                        <div>
                          <p
                            className="p-2 m-0"
                            style={{
                              position: "sticky",
                              top: "0",
                              background: "#e3e6ed",
                            }}
                          >
                            {dateStatus(date)}
                          </p>
                          {data?.map((item, key) => (
                            <div className="activity__section">
                              <div>
                                <ul className="p-0 m-0">
                                  <li>
                                    <p className="m-0">
                                      {item.moduleType === "message"
                                        ? item.activity
                                        : item.dataName +
                                          " Successfully " +
                                          (item.activity === "Add"
                                            ? "Added"
                                            : item.activity === "Update"
                                            ? "Updated"
                                            : item.activity === "Delete"
                                            ? "Deleted"
                                            : item.activity.replace("_", " ") +
                                              " Updated")}
                                    </p>
                                  </li>
                                  <p
                                    className="m-0 p-0"
                                    style={{ color: "gray" }}
                                  >
                                    {timeSince(item?.createdAt)}
                                  </p>
                                </ul>
                              </div>
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Form.Group> */}
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Dashboard;
