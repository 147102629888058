import React, { useState } from "react";
import Swal from "sweetalert2";
import Moment from "react-moment";
import { Form, Table } from "react-bootstrap";
import Pagination from "../../../../Hooks/Pagination";
import { sponserServices } from "../../../../APIServices/sponserServices";
import StudyConfiguration from "./StudyConfiguration";
import UseToggle from "../../../../Hooks/UseToggle";

const SponserStudytableData = ({
  onMouseDown,
  tableRef,
  addStudyData,
  getStudyList,
  setStudyName,
  setStudyDataId,
  setStudyData,
}) => {
  let currentRole = JSON.parse(localStorage.getItem("currentRole"));
  const [highlightedRow, setHighlightedRow] = useState(null);
  const { toggle, setToggle } = UseToggle();
  const loginStudy = (studyId) => {
    window.location.href = `/study/${studyId}`;
    localStorage.setItem("currentRole", JSON.stringify("study"));
    localStorage.setItem("previousRole", JSON.stringify(currentRole));
  };

  const [studyId, setStudyId] = useState("");

  const configuration = (studyId) => {
    setToggle();
    setStudyId(studyId)
  };

  const deleteStudy = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete Study!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = {};
        let results = await sponserServices.deleteStudy(id, data);
        if (results?.statusCode === 200) {
          getStudyList();
        }
      }
    });
  };

  const changeStudyStatus = async (id, status) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Update Study!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = {};
        data.id = id;
        data.status = status;
        let results = await sponserServices.changeStudyStatus(data);
        if (results?.statusCode === 200) {
          getStudyList();
        }
      }
    });
  };

  const handleUpdateClick = (Studydata) => {
    setStudyDataId(Studydata?._id);
    setStudyName(Studydata.studyName);
  };

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = addStudyData?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(addStudyData?.length / itemsPerPage);

  return (
    <>
      <Table
        tableRef={tableRef}
        id="resizable-table"
        className="text-nowrap mb-1 custom-table"
        hover
        bordered
        striped
      >
        <thead className="position-sticky z-1" style={{ top: -1 }}>
          <tr>
            <th onMouseDown={(e) => onMouseDown(e, 1)} style={{ width: 40 }}>
              Sr.
            </th>
            <th onMouseDown={(e) => onMouseDown(e, 2)}>Study Name</th>
            <th onMouseDown={(e) => onMouseDown(e, 3)}>Status</th>
            <th onMouseDown={(e) => onMouseDown(e, 4)}>Version</th>
            <th onMouseDown={(e) => onMouseDown(e, 5)}>CRF Data Type</th>
            <th onMouseDown={(e) => onMouseDown(e, 6)}>Add Date | Time</th>
            <th onMouseDown={(e) => onMouseDown(e, 7)}>Modified Date | Time</th>
            <th onMouseDown={(e) => onMouseDown(e, 8)} style={{ width: 100 }}>
              Configuraton
            </th>
            <th onMouseDown={(e) => onMouseDown(e, 9)} style={{ width: 80 }}>
              <div className="text-center">Login Study</div>
            </th>
            <th onMouseDown={(e) => onMouseDown(e, 10)} style={{ width: 80 }}>
              <div className="text-center">Update</div>
            </th>
            <th onMouseDown={(e) => onMouseDown(e, 11)} style={{ width: 60 }}>
              <div className="text-center">Remove</div>
            </th>
          </tr>
        </thead>
        <tbody>
          {result?.map((item, index) => (
            <tr
              key={index}
              className={`position-relative cursor-pointer ${highlightedRow === index && "activeMenu"
                }`}
              onClick={() => setHighlightedRow(index)}
            >
              <td>{(pageNumber - 1) * itemsPerPage + index + 1}</td>
              <td>{item.studyName}</td>
              <td className="p-0">
                <Form.Select
                  className={`border-0 rounded-0 bg-transparent ${item?.status === "active" ? "text-success" : "text-danger"
                    }`}
                  style={{ fontSize: 12 }}
                  value={item.status}
                  onChange={(e) => changeStudyStatus(item?._id, e.target.value)}
                >
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </Form.Select>
              </td>
              <td>{item.version}</td>
              <td className="text-capitalize">{item.crfData}</td>
              <td>
                <Moment format="DD MMM YYYY | HH:mm:ss">
                  {item.createdAt}
                </Moment>
              </td>
              <td>
                <Moment format="DD MMM YYYY | HH:mm:ss">
                  {item.updatedAt}
                </Moment>
              </td>
              <td>
                <div
                  className="text-center"
                  title="Study Configuration"
                  onClick={() => configuration(item?._id)}
                >
                  <i className={`fa-solid fa-gear`}></i>
                </div>
              </td>
              <td>
                <div
                  className="text-center"
                  title="Login Study"
                  onClick={() => loginStudy(item?._id)}
                >
                  <i className={`fa-solid fa-right-to-bracket`}></i>
                </div>
              </td>
              <td className="text-center">
                <button
                  onClick={() => handleUpdateClick(item)}
                  className="text-white border-primary bg-primary border-0"
                  style={{
                    fontSize: 10,
                    borderRadius: 3,
                    padding: "2px 8px",
                  }}
                >
                  Update
                </button>
              </td>
              <td>
                <div
                  className="text-center"
                  onClick={() => deleteStudy(item?._id)}
                >
                  <i
                    title="Delete Study"
                    className={`fa-solid fa-trash-can CP red text-danger`}
                  ></i>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="position-sticky bottom-0">
        <Pagination
          totalPage={totalPage}
          pageNumber={pageNumber}
          itemsPerPage={itemsPerPage}
          totalItems={addStudyData?.length}
          setPageNumber={setPageNumber}
          setItemsPerPage={setItemsPerPage}
        />
      </div>
      <StudyConfiguration
        Show={!toggle}
        Hide={setToggle}
        getStudyList={getStudyList}
        Title={"Study Configuration"}
        studyId={studyId}
      />
    </>
  );
};

export default SponserStudytableData;
