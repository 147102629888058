import React, { useEffect, useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import { CiViewTable } from "react-icons/ci";
import SplitPane from "react-split-pane";
import { BiCollapse, BiExpand } from "react-icons/bi";
// import { cdiscServices } from "../../../APIServices/cdiscServices";
import LegacyTableProperties from "./LegacyTableProperties";
import LegacyTableData from "./LegacyTableData";
import ImportLegacy from "./ImportLegacy";
import { TbLayoutSidebarRightExpand } from "react-icons/tb";
import { AiOutlinePlus } from "react-icons/ai";
import { CgChevronDown } from "react-icons/cg";
import PropertyTable from "./PropertyTable";
import DatasetTable from "./DatasetTable";
import UseToggle from "../../../../Hooks/UseToggle";
// import SDATableProperties from "./SDATableProperties";
// import SDTAVariableTableData from "./SDATableData";

const Legacy = ({ onMouseDown, tableRef }) => {
  const [loader, setLoader] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const { toggle, setToggle } = UseToggle();
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [stdmDataSets, setSTDMDataSets] = useState([]);
  const [isOpenProperty, setIsOpenProperty] = useState(false);
  const [isHalfScreen, setIsHalfScreen] = useState(false);
  const [activeTabs, setActiveTabs] = useState("properties");

  // const currentUrl = window.location.href;
  // const splitUrl = currentUrl?.split("/");
  // const studyId = splitUrl[4];

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  const toggleHalfScreen = () => {
    setIsHalfScreen(!isHalfScreen);
  };

  // const getCDISCDatasets = async () => {
  //   setLoader(true);
  //   let data = await cdiscServices.getCDISCDatasets(studyId);
  //   setSTDMDataSets(data?.data || []);
  //   setLoader(false);
  // };

  const HeaderTools = [
    {
      title: isFormOpen ? "Hide Form" : "Import Legacy",
      icon: isFormOpen ? <TbLayoutSidebarRightExpand /> : <AiOutlinePlus />,
      onClick: () => setIsFormOpen(!isFormOpen),
    },
    {
      title: "View Table Properties",
      icon: <CiViewTable />,
      onClick: setToggle,
    },
  ];

  // useEffect(() => {
  //   getCDISCDatasets();
  // }, []);

  return (
    <div className={`code-list-container ${isFullScreen ? "full-screen" : ""}`}>
      <div
        className="hstack justify-content-between w-100 border-bottom"
        style={{ padding: "4.6px 9px" }}
      >
        <div className="hstack gap-2">
          {HeaderTools?.map((button, index) => (
            <React.Fragment key={index}>
              <button
                title={button.title}
                className="tabButtons border rounded-1 fs-13"
                style={{ padding: "1px 5px" }}
                onClick={button.onClick}
              >
                {button.icon}
              </button>
              {index < 1 && <div className="vr"></div>}
            </React.Fragment>
          ))}
        </div>
        <button
          className="tabButtons border rounded-1 fs-13"
          title={!isFullScreen ? "Full Mode" : "Original Mode"}
          onClick={toggleFullScreen}
        >
          {isFullScreen ? <BiCollapse /> : <BiExpand />}
        </button>
      </div>
      <div className="position-relative">
        <SplitPane
          split="horizontal"
          style={{
            height: isFullScreen ? "calc(100vh - 35px)" : "calc(100vh - 148px)",
          }}
          defaultSize={isOpenProperty ? "40%" : "100%"}
        >
          <SplitPane
            // split="vertical"
            minSize={20}
            defaultSize={isFormOpen ? "20%" : "100%"}
          >
            {isFormOpen && <ImportLegacy />}
            <>
              {loader ? (
                <div className="hstack justify-content-center h-100 w-100">
                  <Spinner />
                </div>
              ) : stdmDataSets?.length > 0 ? (
                <div className="hstack justify-content-center h-100 w-100">
                  Dataset Not Found!
                </div>
              ) : (
                <LegacyTableData
                  // stdmDataSets={stdmDataSets}
                  // getCDISCDatasets={getCDISCDatasets}
                  onMouseDown={onMouseDown}
                  tableRef={tableRef}
                  setIsOpenProperty={setIsOpenProperty}
                />
              )}
            </>
          </SplitPane>
          {isOpenProperty && (
            <div
              className={`${isHalfScreen ? "full-screen" : ""}`}
              style={{ height: "100%" }}
            >
              <div
                className="hstack justify-content-between border-bottom px-2 pb-0 bg-light"
                style={{ padding: 5 }}
              >
                <div className="hstack me-1 gap-1">
                  {["properties", "dataset"].map((tab) => (
                    <button
                      key={tab}
                      className={`buttonForTabs px-2 ${
                        activeTabs === tab ? "activee" : ""
                      }`}
                      onClick={() => setActiveTabs(tab)}
                      title={tab.charAt(0).toUpperCase() + tab.slice(1)}
                      style={{ borderRadius: "3px 3px 0px 0px" }}
                    >
                      {tab.charAt(0).toUpperCase() + tab.slice(1)}
                    </button>
                  ))}
                </div>
                <div className="hstack gap-1">
                  <button
                    className={`tabButtons border-0 fs-13 px-2`}
                    style={{ borderRadius: "3px 3px 0px 0px" }}
                    title={"Hide"}
                    onClick={() => setIsOpenProperty(false)}
                  >
                    <CgChevronDown />
                  </button>
                  <button
                    className={`tabButtons border-0 fs-13 px-2`}
                    style={{ borderRadius: "3px 3px 0px 0px" }}
                    title={!isHalfScreen ? "Full Mode" : "Original Mode"}
                    onClick={toggleHalfScreen}
                  >
                    {isHalfScreen ? <BiCollapse /> : <BiExpand />}
                  </button>
                </div>
              </div>
              <SplitPane
                split="horizontal"
                className="position-relative"
                defaultSize="100%"
              >
                {activeTabs === "properties" ? (
                  <PropertyTable />
                ) : activeTabs === "dataset" ? (
                  <DatasetTable />
                ) : (
                  ""
                )}
              </SplitPane>
            </div>
          )}
        </SplitPane>
      </div>
      <LegacyTableProperties
        Show={!toggle}
        Hide={setToggle}
        Title={"Table Properties"}
      />
    </div>
  );
};

export default Legacy;
