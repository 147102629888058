import React, { useState } from "react";
import { Table } from "react-bootstrap";
import Pagination from "../../../../Hooks/Pagination";
import { cdiscServices } from "../../../../APIServices/cdiscServices";
import Swal from "sweetalert2";

const ValueLabelTableData = ({
  valueLaveldata,
  tableRef,
  onMouseDown,
  getValueLevelData,
}) => {
  const deleteStudyValueLevelData = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete Value lavel!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let results = await cdiscServices.deleteStudyValueLevelData(id);
        if (results?.statusCode === 200) {
          getValueLevelData();
        }
      }
    });
  };
  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = valueLaveldata?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(valueLaveldata?.length / itemsPerPage);
  return (
    <>
      <div className="overflow-auto">
        <Table
          tableRef={tableRef}
          id="resizable-table"
          className="text-nowrap custom-table tableLibrary"
          hover
          bordered
          striped
        >
          <thead className="position-sticky z-1" style={{ top: -1 }}>
            <tr>
              <th onMouseDown={(e) => onMouseDown(e, 1)}>Order</th>
              <th onMouseDown={(e) => onMouseDown(e, 2)}>Dataset</th>
              <th onMouseDown={(e) => onMouseDown(e, 3)}>Variable</th>
              <th onMouseDown={(e) => onMouseDown(e, 4)}>Where Clause</th>
              <th onMouseDown={(e) => onMouseDown(e, 5)}>Data Type</th>
              <th onMouseDown={(e) => onMouseDown(e, 6)}>Length</th>
              <th onMouseDown={(e) => onMouseDown(e, 7)}>Significant</th>
              <th onMouseDown={(e) => onMouseDown(e, 8)}>Format</th>
              <th onMouseDown={(e) => onMouseDown(e, 9)}>Mandatory</th>
              <th onMouseDown={(e) => onMouseDown(e, 10)}>Codelist</th>
              <th onMouseDown={(e) => onMouseDown(e, 11)}>Origin</th>
              <th onMouseDown={(e) => onMouseDown(e, 12)}>Pages</th>
              <th onMouseDown={(e) => onMouseDown(e, 13)}>Method</th>
              <th onMouseDown={(e) => onMouseDown(e, 14)}>Predecessor</th>
              <th onMouseDown={(e) => onMouseDown(e, 15)}>
                Value Level Comment
              </th>
              <th onMouseDown={(e) => onMouseDown(e, 16)}>Join Comment</th>
              <th
                onMouseDown={(e) => onMouseDown(e, 17)}
                style={{ width: 70, right: -1 }}
                className="text-center position-sticky"
              >
                Delete
              </th>
            </tr>
          </thead>
          <tbody>
            {result?.map((item, index) => (
              <tr key={index} className="position-relative CP">
                <td>{item.order}</td>
                <td>{item.domain}</td>
                <td>{item.domainValueLevelVeriableName}</td>
                <td>{item.domainWhereclauseVeriableName}</td>
                <td className="text-capitalize">{item.dataType}</td>
                <td>{item.length}</td>
                <td>{item.significant}</td>
                <td>{item.formate}</td>
                <td className="text-capitalize">{item.mandatory}</td>
                <td>{item.codeListData}</td>
                <td>{item.origin}</td>
                <td>{item.page}</td>
                <td>{item.mathod}</td>
                <td>{item.predecessor}</td>
                <td>{item.comment}</td>
                <td>{item.joinComment}</td>
                <td style={{ right: -1 }} className="position-sticky bg-light">
                  <div
                    className="text-center cursor-pointer"
                    title="Delete Supplementary"
                    onClick={() => deleteStudyValueLevelData(item?._id)}
                  >
                    <i className={`fa-solid fa-trash-can text-danger`}></i>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <div className="position-sticky bottom-0">
        <Pagination
          totalPage={totalPage}
          pageNumber={pageNumber}
          itemsPerPage={itemsPerPage}
          totalItems={valueLaveldata?.length}
          setPageNumber={setPageNumber}
          setItemsPerPage={setItemsPerPage}
        />
      </div>
    </>
  );
};

export default ValueLabelTableData;
