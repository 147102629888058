import React from "react";
import { Card, Table } from "react-bootstrap";

const ClientStudyBookMark = ({ tableRef, onMouseDown }) => {
  const clientStudyBookMark = [
    {
      id: "1",
      client: "Client 1",
      projectId: "Project 1",
      studyId: "Study 1",
    },
    {
      id: "2",
      client: "Client 2",
      projectId: "Project 2",
      studyId: "Study 2",
    },
    {
      id: "3",
      client: "Client 3",
      projectId: "Project 3",
      studyId: "Study 3",
    },
  ];
  return (
    <div>
      <Card.Text className="m-0 mt-1">
        <div style={{ height: 235, overflow: "auto" }}>
          <Table
            id="resizable-table"
            striped
            bordered
            className="m-0 custom-table"
            ref={tableRef}
          >
            <thead className="position-sticky top-0">
              <tr>
                <th
                  style={{ width: 70 }}
                  onMouseDown={(e) => onMouseDown(e, 0)}
                >
                  Database
                </th>
                <th onMouseDown={(e) => onMouseDown(e, 1)}>Client Name</th>
                <th onMouseDown={(e) => onMouseDown(e, 2)}>Project Id</th>
                <th onMouseDown={(e) => onMouseDown(e, 3)}>Study Id</th>
              </tr>
            </thead>
            <tbody>
              {clientStudyBookMark?.map((item, i) => (
                <tr>
                  <td
                    className="text-center cursor-pointer"
                    title={`Login to ${item.studyId}`}
                  >
                    <i
                      className="fa-solid fa-database"
                      title="Login in Study"
                    ></i>
                  </td>
                  <td>{item.client}</td>
                  <td>{item.projectId}</td>
                  <td>{item.studyId}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </Card.Text>
    </div>
  );
};

export default ClientStudyBookMark;
