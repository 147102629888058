import React, { useState } from "react";
import Swal from "sweetalert2";
import Moment from "react-moment";
import { Form, Table } from "react-bootstrap";
import { sponserServices } from "../../../../APIServices/sponserServices";
import Pagination from "../../../../Hooks/Pagination";

const SponserListPropertiesTableData = ({
  getProjectList,
  addProjectData,
  onMouseDown,
  tableRef,
  setProjectName,
  setSelectedSponsorId,
  setProjectData,
}) => {
  const [highlightedRow, setHighlightedRow] = useState(null);
  const deleteProject = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete Project!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = {};
        let results = await sponserServices.deleteProject(id, data);
        if (results?.statusCode === 200) {
          getProjectList();
        }
      }
    });
  };

  const changeProjectStatus = async (id, status) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Update Project!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = {};
        data.id = id;
        data.status = status;
        let results = await sponserServices.changeProjectStatus(data);
        if (results?.statusCode === 200) {
          getProjectList();
        }
      }
    });
  };

  // const handleUpdateAllData = (item) => {
  //     setProjectTableId(item?._id)
  // }

  const handleUpdateClick = (sponser) => {
    setSelectedSponsorId(sponser?._id);
    setProjectName(sponser.projectName);
  };

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = addProjectData?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(addProjectData?.length / itemsPerPage);

  return (
    <>
      <Table
        tableRef={tableRef}
        id="resizable-table"
        className="text-nowrap mb-1 custom-table"
        hover
        bordered
        striped
      >
        <thead className="position-sticky z-1" style={{ top: -1 }}>
          <tr>
            <th onMouseDown={(e) => onMouseDown(e, 1)} style={{ width: 40 }}>
              Sr.
            </th>
            <th onMouseDown={(e) => onMouseDown(e, 2)}>Project Name</th>
            <th onMouseDown={(e) => onMouseDown(e, 3)}>Status</th>
            <th onMouseDown={(e) => onMouseDown(e, 5)}>Add Date | Time</th>
            <th onMouseDown={(e) => onMouseDown(e, 6)}>Modified Date | Time</th>
            <th onMouseDown={(e) => onMouseDown(e, 7)} style={{ width: 80 }}>
              <div className="text-center">Update</div>
            </th>
            <th onMouseDown={(e) => onMouseDown(e, 8)} style={{ width: 60 }}>
              <div className="text-center">Remove</div>
            </th>
          </tr>
        </thead>
        <tbody>
          {result?.map((item, index) => (
            <tr key={index} className={`position-relative cursor-pointer ${highlightedRow === index && "activeMenu"}`}
              onClick={() => {setProjectData(item); setHighlightedRow(index)}}>
              <td>
                {(pageNumber - 1) * itemsPerPage + index + 1}
              </td>
              <td>{item.projectName}</td>
              <td className="p-0">
                <Form.Select
                  className={`border-0 rounded-0 bg-transparent ${
                    item?.status === "active" ? "text-success" : "text-danger"
                  }`}
                  style={{ fontSize: 12 }}
                  value={item.status}
                  onChange={(e) =>
                    changeProjectStatus(item?._id, e.target.value)
                  }
                >
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </Form.Select>
              </td>
              <td>
                <Moment format="DD MMM YYYY | HH:mm:ss">
                  {item.createdAt}
                </Moment>
              </td>
              <td>
                <Moment format="DD MMM YYYY | HH:mm:ss">
                  {item.updatedAt}
                </Moment>
              </td>
              <td className="text-center">
                <button
                  onClick={() => handleUpdateClick(item)}
                  className="text-white border-primary bg-primary border-0"
                  style={{
                    fontSize: 10,
                    borderRadius: 3,
                    padding: "2px 8px",
                  }}
                >
                  Update
                </button>
              </td>
              <td>
                <div
                  className="text-center"
                  onClick={() => deleteProject(item?._id)}
                >
                  <i
                    title="Delete Protocol"
                    className={`fa-solid fa-trash-can CP red text-danger`}
                  ></i>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="position-sticky bottom-0">
        <Pagination
          totalPage={totalPage}
          pageNumber={pageNumber}
          itemsPerPage={itemsPerPage}
          totalItems={addProjectData?.length}
          setPageNumber={setPageNumber}
          setItemsPerPage={setItemsPerPage}
        />
      </div>
    </>
  );
};

export default SponserListPropertiesTableData;
