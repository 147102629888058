import React, { useState } from "react";
import { cdiscServices } from "../../../../APIServices/cdiscServices";
import { Form } from "react-bootstrap";
import { FaPlusSquare } from "react-icons/fa";

const AddComments = ({
  setCommentId,
  commentId,
  setDescription,
  description,
  getSupplimentalCommentsList,
  selectedCommentsId,
  setSelectedCommentsId,
}) => {
  const [statusMessage, setStatusMessage] = useState("");
  const [statusCode, setStatusCode] = useState(false);
  const [loader, setLoader] = useState(false);

  const handleSubmit = async (e) => {
    setLoader(true);
    e.preventDefault();
    let userData = {};
    userData.commentId = commentId;
    userData.description = description;
    let data = await cdiscServices.addNewSupplimentalComment(userData);
    getSupplimentalCommentsList();
    setStatusMessage(data);
    if (data?.statusCode === 200) {
      setLoader(false);
      setCommentId("");
      setDescription("");
      setStatusCode(true);
    }
    setTimeout(() => {
      setStatusMessage("");
    }, 3000);
  };

  const updateSupplimentalComments = async (e) => {
    setLoader(true);
    e.preventDefault();
    let userData = {};
    userData.id = selectedCommentsId;
    userData.commentId = commentId;
    userData.description = description;
    let data = await cdiscServices.updateSupplimentalComments(userData);
    getSupplimentalCommentsList();
    setStatusMessage(data);
    if (data?.statusCode === 200) {
      setLoader(false);
      clearFormData();
      setCommentId("");
      setDescription("");
      setStatusCode(true);
      setSelectedCommentsId("");
    }
    setTimeout(() => {
      setStatusMessage("");
    }, 3000);
  };

  const clearFormData = () => {
    setCommentId("");
    setDescription("");
    setSelectedCommentsId("");
  };

  return (
    <div>
      <Form.Group className="mb-2">
        <div className="hstack justify-content-between">
          <Form.Label className="mb-1 fw-bold">
            Comment Name<span className="text-danger">*</span>
          </Form.Label>
          {selectedCommentsId && (
            <button
              className="fs10 br3 text-white border-primary bg-primary border-0"
              title="Add new Comment"
              onClick={() => clearFormData()}
            >
              <FaPlusSquare style={{ marginBottom: 2 }} /> Add New
            </button>
          )}
        </div>
        <Form.Control
          required
          type="text"
          placeholder="Enter Methods Name"
          value={commentId}
          onChange={(e) => setCommentId(e.target.value)}
        />
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">
          Description<span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          required
          type="text"
          placeholder="Enter Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </Form.Group>
      <Form.Group className="text-end">
        <button
          className="p-1 px-3 border rounded-1 text-white bg-color fs-13"
          onClick={
            !selectedCommentsId ? handleSubmit : updateSupplimentalComments
          }
        >
          <FaPlusSquare style={{ marginBottom: 2 }} />
          {!selectedCommentsId ? "Add" : "Update"} Methods
        </button>
      </Form.Group>
    </div>
  );
};

export default AddComments;
