import React, { useEffect, useState } from "react";
import { Form, Offcanvas } from "react-bootstrap";
import { FaCheck } from "react-icons/fa6";
import { cdiscServices } from "../../../../APIServices/cdiscServices";
import { sponserServices } from "../../../../APIServices/sponserServices";

const StudyConfiguration = ({ Show, Hide, Title, studyId, getStudyList }) => {
  const [versionList, setVersionList] = useState([]);
  const [version, setVersion] = useState("");
  const [CRFData, setCRFData] = useState("");

  const getActiveVersions = async () => {
    let data = await cdiscServices.getActiveVersions();
    setVersionList(data?.data);
  };

  const updateConfiguration = async (e) => {
    let userData = {};
    userData.id = studyId;
    userData.version = version;
    userData.crfData = CRFData;
    let data = await sponserServices.updateStudy(userData);
    if (data?.statusCode === 200) {
      getStudyList();
      Hide();
    }
  };

  useEffect(() => {
    getActiveVersions();
  }, []);
  return (
    <>
      <Offcanvas show={Show} onHide={Hide} placement="end">
        <Offcanvas.Header>
          <Offcanvas.Title>{Title} </Offcanvas.Title>
          <div onClick={Hide} className="close_modal" title="Close">
            X
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0">
          <div
            className="pt-2 px-3 overflow-auto"
            style={{ height: "calc(100vh - 84px)" }}
          >
            <Form.Group className="mb-2">
              <Form.Label className="mb-1 fw-bold">Version</Form.Label>
              <Form.Select
                onChange={(e) => setVersion(e.target.value)}
              >
                <option value="">Select Version</option>
                {versionList?.map((item, index) => (
                  <option
                    selected={version === item._id}
                    key={index}
                    value={item._id}
                  >
                    {item.version}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label className="mb-1 fw-bold">CRF Data</Form.Label>
              <Form.Select onClick={(e) => setCRFData(e.target.value)}>
                <option value="">Select CRF Data Type</option>
                <option selected={CRFData === "custom"} value="custom">
                  Custom
                </option>
                <option
                  selected={CRFData === "predefine"}
                  value="predefine"
                >
                  Predefine
                </option>
              </Form.Select>
            </Form.Group>
          </div>
          <div className="text-end py-2 px-3 shadow-sm bg-light w-100">
            <button
              disabled={!version || !CRFData}
              className="py-1 px-3 border rounded-1 text-white bg-color fs-13"
              onClick={() => updateConfiguration()}
            >
              <FaCheck /> Apply
            </button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default StudyConfiguration;
