import React, { useEffect, useState } from "react";
import { FaUsers } from "react-icons/fa";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import UserDashboardStudybookmark from "../Component/ClientDashboard/ClientDashboardTabData";
// import ClientDashboardClientDataChart from "../Component/Chart/ClientDashboardClientDataChart";

const ClientDashboard = ({ userCurrentData, tableRef, onMouseDown, tabs }) => {
  const [greeting, setGreeting] = useState("");

  const getGreeting = () => {
    const currentTime = new Date().getHours();
    let greeting;

    if (currentTime >= 5 && currentTime < 12) {
      greeting = "Good Morning";
    } else if (currentTime >= 12 && currentTime < 18) {
      greeting = "Good Afternoon";
    } else {
      greeting = "Good Evening";
    }
    setGreeting(greeting);
  };

  useEffect(() => {
    getGreeting();
  }, []);

  return (
    <>
      <div className="border-bottom">
        <div className="text-center py-2">
          <h5 className="m-0">
            {greeting}, {userCurrentData?.fullName}
          </h5>
          <Form.Label className="m-0">
            Welcome to Your CDISC Dashboard.
          </Form.Label>
        </div>
      </div>
      <div
        className="overflow-auto py-2"
        style={{ height: tabs?.length > 0 ? "calc(100vh - 180px)" : "calc(100vh - 145px)" }}
      >
        <Container fluid>
          <Row className="chart__overview">
            <Form.Group as={Col} md={4} sm={12}>
              <Card className="mb-3 bg-light border">
                <Card.Body>
                  <div className="d-flex gap-3">
                    <div className="study_icopn">
                      <FaUsers className="rounded-1 bg-white shadow-sm " />
                    </div>
                    <div>
                      <Card.Title>Active Clients</Card.Title>
                      <Card.Text className="m-0">45</Card.Text>
                      <Card.Text>new client added in past 1 month.</Card.Text>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Form.Group>
            <Form.Group as={Col} md={4} sm={12}>
              <Card className="mb-3 bg-light border">
                <Card.Body>
                  <div className="d-flex gap-3">
                    <div className="study_icopn">
                      <FaUsers className="rounded-1 bg-white shadow-sm " />
                    </div>
                    <div>
                      <Card.Title>Active Study</Card.Title>
                      <Card.Text className="m-0">45</Card.Text>
                      <Card.Text>new client added in past 1 month.</Card.Text>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Form.Group>
            <Form.Group as={Col} md={4} sm={12}>
              <Card className="mb-3 bg-light border">
                <Card.Body>
                  <div className="d-flex gap-3">
                    <div className="study_icopn">
                      <FaUsers className="rounded-1 bg-white shadow-sm " />
                    </div>
                    <div>
                      <Card.Title>Active Project</Card.Title>
                      <Card.Text className="m-0">45</Card.Text>
                      <Card.Text>new client added in past 1 month.</Card.Text>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Form.Group>
            {/* <Form.Group as={Col} md={4} sm={12}>
          <Card className="mb-3 bg-light border">
            <Card.Body style={{ height: 295 }}>
              <ClientDashboardClientDataChart />
            </Card.Body>
          </Card>
        </Form.Group> */}
            <Form.Group as={Col} md={8} sm={12}>
              <Card className="mb-3 bg-light border">
                <Card.Body>
                  <UserDashboardStudybookmark
                    onMouseDown={onMouseDown}
                    tableRef={tableRef}
                  />
                </Card.Body>
              </Card>
            </Form.Group>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ClientDashboard;
