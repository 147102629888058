import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { FaPlusSquare } from "react-icons/fa";
import { cdiscServices } from "../../../APIServices/cdiscServices";

const ImportVariable = ({ versionId, getCDISCVariablesList }) => {
  const [classId, setClassId] = useState("");
  const [domainId, setDomainId] = useState("");
  const [domain, setDomain] = useState([]);
  const [sdtmClass, setSDTMClass] = useState([]);
  const [statusMessage, setStatusMessage] = useState("");

  const currentUrl = window.location.href;
  const splitUrl = currentUrl?.split("/");
  const studyId = splitUrl[4];

  const handleSubmit = async (e) => {
    e.preventDefault();
    let userData = {};
    userData.studyId = studyId;
    userData.versionId = versionId;
    userData.classId = classId;
    userData.domainId = domainId;
    let data = await cdiscServices.addNewCDISCVariable(userData);
    getCDISCVariablesList();
    setStatusMessage(data);
    if (data?.statusCode === 200) {
      setClassId("");
      setDomainId("");
    }
    setTimeout(() => {
      setStatusMessage("");
    }, 3000);
  };

  const getActiveClass = async (versionId) => {
    let data = await cdiscServices.getActiveClass(versionId);
    setSDTMClass(data?.data);
  };

  const getActiveDomains = async (classId) => {
    let data = await cdiscServices.getActiveDomains(classId);
    setDomain(data?.data);
  };

  useEffect(() => {
    getActiveClass(versionId);
  }, [versionId]);

  return (
    <>
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">
          Class <span className="text-danger">*</span>
        </Form.Label>
        <Form.Select
          onChange={(e) => {
            setClassId(e.target.value);
            getActiveDomains(e.target.value);
          }}
          value={classId}
        >
          <option value="">Select Class</option>
          {sdtmClass?.map((item, index) => (
            <option
              selected={item.classId === item._id}
              key={index}
              value={item._id}
            >
              {item.class}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">
          Domain <span className="text-danger">*</span>
        </Form.Label>
        <Form.Select
          onChange={(e) => setDomainId(e.target.value)}
          value={domainId}
        >
          <option value="">Select Domain</option>
          {domain?.map((item, index) => (
            <option
              selected={item.domainId === item._id}
              key={index}
              value={item._id}
            >
              {item.domain}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
      <Form.Group className="text-end">
        <button
          className="p-1 px-3 border rounded-1 text-white bg-color fs-13"
          onClick={handleSubmit}
        >
          <FaPlusSquare style={{ marginBottom: 2 }} /> Import Variable
        </button>
      </Form.Group>
    </>
  );
};

export default ImportVariable;
