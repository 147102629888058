import React from "react";
import { Form } from "react-bootstrap";
import { FaCheck } from "react-icons/fa6";

const UpdateVariable = () => {
  return (
    <>
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">Order</Form.Label>
        <Form.Control type="text" />
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">Dataset</Form.Label>
        <Form.Control type="text" />
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">Variable</Form.Label>
        <Form.Control type="text" />
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">Label</Form.Label>
        <Form.Control type="text" />
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">Data Type</Form.Label>
        <Form.Control type="text" />
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">Length</Form.Label>
        <Form.Control type="text" />
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">Significant</Form.Label>
        <Form.Control type="text" />
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">Format</Form.Label>
        <Form.Control type="text" />
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">Mandatory</Form.Label>
        <Form.Control type="text" />
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">Codelist</Form.Label>
        <Form.Control type="text" />
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">Origin</Form.Label>
        <Form.Control type="text" />
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">Pages</Form.Label>
        <Form.Control type="text" />
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">Method</Form.Label>
        <Form.Control type="text" />
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">Predecessor</Form.Label>
        <Form.Control type="text" />
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">Role</Form.Label>
        <Form.Control type="text" />
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">Role</Form.Label>
        <Form.Control type="text" />
      </Form.Group>
      <Form.Group className="text-end">
        <button className="p-1 px-3 border rounded-1 text-white bg-color fs-13">
          <FaCheck style={{ marginBottom: 2 }} /> Update
        </button>
      </Form.Group>
    </>
  );
};

export default UpdateVariable;
