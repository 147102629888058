import React, { useState } from "react";
import { authServices } from "../../../APIServices/authServices";
import { Form, Modal, Row } from "react-bootstrap";
import TwoFactorAuthByEmail from "./TwoFactorAuthByEmail";
import TwoFactorAuthByApps from "./TwoFactorAuthByApps";

const TwoFA = ({
  Show,
  Hide,
  Size,
  Title,
  setAuthType,
  authType,
  status,
  appTwoFA,
  emailTwoFA,
  getTwoFactorAuthType,
}) => {
  const [nextBy, setNextBy] = useState(false);
  const [modalSize, setModalSize] = useState(false);

  const setAuthenticationType = async () => {
    let userData = {};
    userData.authType = authType;
    let data = await authServices.twoFactorAuthType(userData);
    if (data?.statusCode === 200) {
      getTwoFactorAuthType();
      setNextBy(true);
    }
  };

  const hideModalData = () => {
    Hide();
    setNextBy(false);
    setModalSize(false);
  };

  let twoFAData = emailTwoFA || appTwoFA;

  return (
    <Modal
      show={Show}
      onHide={hideModalData}
      size={!modalSize ? Size : "sm"}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>{Title}</Modal.Title>
        <div onClick={hideModalData} className="close_modal" title="Close">
          X
        </div>
      </Modal.Header>
      <Modal.Body>
        <>
          {!nextBy && !twoFAData ? (
            <Row>
              <Form.Group>
                <Form.Label className="mb-1 fw-bold">
                  Authentication Type <span className="text-danger">*</span>
                </Form.Label>
                <Form.Select
                  disabled={status}
                  onChange={(e) => setAuthType(e.target.value)}
                >
                  <option value="">Select Authentication Type</option>
                  <option selected={authType === "email"} value="email">
                    By Email
                  </option>
                  <option selected={authType === "apps"} value="apps">
                    By Apps
                  </option>
                </Form.Select>
              </Form.Group>
              <div className="hstack gap-3 justify-content-end pt-1">
                <button
                  className="p-1 px-3 border rounded-1 text-white bg-color fs-13"
                  onClick={() => (setNextBy(true), setAuthenticationType())}
                >
                  Next
                </button>
              </div>
            </Row>
          ) : (
            <>
              {authType === "email" ? (
                <TwoFactorAuthByEmail
                  setNextBy={setNextBy}
                  setModalSize={setModalSize}
                  emailTwoFA={emailTwoFA}
                  getTwoFactorAuthType={getTwoFactorAuthType}
                />
              ) : authType === "apps" ? (
                <TwoFactorAuthByApps
                  setNextBy={setNextBy}
                  setModalSize={setModalSize}
                  appTwoFA={appTwoFA}
                  getTwoFactorAuthType={getTwoFactorAuthType}
                />
              ) : (
                ""
              )}
            </>
          )}
        </>
      </Modal.Body>
    </Modal>
  );
};

export default TwoFA;
