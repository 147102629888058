import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { CiFilter, CiViewTable } from "react-icons/ci";
import SplitPane from "react-split-pane";
import { BiCollapse, BiExpand } from "react-icons/bi";
import STDMCodeListTableProperties from "./STDMCodeListTableProperties";
import STDMCodeListTableData from "./STDMCodeListTableData";
import UseToggle from "../../../../Hooks/UseToggle";
import { cdiscServices } from "../../../../APIServices/cdiscServices";
import { CgChevronDown } from "react-icons/cg";
import STDMCodeListFilter from "./STDMCodeListFilter";
import Properties from "./Properties";

const STDMCodeList = ({ onMouseDown, tableRef }) => {
  const [loader, setLoader] = useState(false);
  const { toggle, setToggle, toggle1, setToggle1 } = UseToggle();
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [codeList, setCodeList] = useState([]);
  const [isOpenProperty, setIsOpenProperty] = useState(false);
  const [isHalfScreen, setIsHalfScreen] = useState(false);
  const [activeTabs, setActiveTabs] = useState("properties");
  const [codeListId, setCodeListId] = useState("");

  const currentUrl = window.location.href;
  const splitUrl = currentUrl?.split("/");
  const studyId = splitUrl[4];

  const toggleHalfScreen = () => {
    setIsHalfScreen(!isHalfScreen);
  };

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  const getCDISCCodeLists = async () => {
    setLoader(true);
    let data = await cdiscServices.getCDISCCodeLists(studyId);
    setCodeList(data?.data || []);
    setLoader(false);
  };

  const HeaderTools = [
    {
      title: "View Table Properties",
      icon: <CiViewTable />,
      onClick: setToggle,
    },
    { title: "Filter", icon: <CiFilter />, onClick: setToggle1 },
  ];

  useEffect(() => {
    getCDISCCodeLists();
  }, []);

  return (
    <div className={`code-list-container ${isFullScreen ? "full-screen" : ""}`}>
      <div
        className="hstack justify-content-between w-100 border-bottom"
        style={{ padding: "4.6px 9px" }}
      >
        <div className="hstack gap-2">
          {HeaderTools?.map((button, index) => (
            <React.Fragment key={index}>
              <button
                title={button.title}
                className="tabButtons border rounded-1 fs-13"
                style={{ padding: "1px 5px" }}
                onClick={button.onClick}
              >
                {button.icon}
              </button>
              {index < 1 && <div className="vr"></div>}
            </React.Fragment>
          ))}
        </div>
        <button
          className="tabButtons border rounded-1 fs-13"
          title={!isFullScreen ? "Full Mode" : "Original Mode"}
          onClick={toggleFullScreen}
        >
          {isFullScreen ? <BiCollapse /> : <BiExpand />}
        </button>
      </div>
      <div className="position-relative">
        <SplitPane
          split="horizontal"
          style={{
            height: isFullScreen ? "calc(100vh - 35px)" : "calc(100vh - 148px)",
          }}
          defaultSize={isOpenProperty ? "40%" : "100%"}
        >
          <SplitPane split="vertical" minSize={20} defaultSize={"100%"}>
            <>
              {loader ? (
                <div className="hstack justify-content-center h-100 w-100">
                  <Spinner />
                </div>
              ) : codeList?.length === 0 ? (
                <div className="hstack justify-content-center h-100 w-100">
                  Data not found!
                </div>
              ) : (
                <STDMCodeListTableData
                  codeList={codeList}
                  tableRef={tableRef}
                  onMouseDown={onMouseDown}
                  getCDISCCodeLists={getCDISCCodeLists}
                  setIsOpenProperty={setIsOpenProperty}
                  setCodeListId={setCodeListId}
                />
              )}
            </>
          </SplitPane>
          {isOpenProperty && (
            <div
              className={`${isHalfScreen ? "full-screen" : ""}`}
              style={{ height: "100%" }}
            >
              <div
                className="hstack justify-content-between border-bottom px-2 pb-0 bg-light"
                style={{ padding: 5 }}
              >
                <div className="hstack me-1 gap-1">
                  {["properties"].map((tab) => (
                    <button
                      key={tab}
                      className={`buttonForTabs px-2 ${
                        activeTabs === tab ? "activee" : ""
                      }`}
                      onClick={() => setActiveTabs(tab)}
                      title={tab.charAt(0).toUpperCase() + tab.slice(1)}
                      style={{ borderRadius: "3px 3px 0px 0px" }}
                    >
                      {tab.charAt(0).toUpperCase() + tab.slice(1)}
                    </button>
                  ))}
                </div>
                <div className="hstack gap-1">
                  <button
                    className={`tabButtons border-0 fs-13 px-2`}
                    style={{ borderRadius: "3px 3px 0px 0px" }}
                    title={"Hide"}
                    onClick={() => setIsOpenProperty(false)}
                  >
                    <CgChevronDown />
                  </button>
                  <button
                    className={`tabButtons border-0 fs-13 px-2`}
                    style={{ borderRadius: "3px 3px 0px 0px" }}
                    title={!isHalfScreen ? "Full Mode" : "Original Mode"}
                    onClick={toggleHalfScreen}
                  >
                    {isHalfScreen ? <BiCollapse /> : <BiExpand />}
                  </button>
                </div>
              </div>
              <SplitPane
                split="horizontal"
                className="position-relative"
                defaultSize="100%"
              >
                {activeTabs === "properties" ? (
                  <Properties codeListId={codeListId} />
                ) : (
                  ""
                )}
              </SplitPane>
            </div>
          )}
        </SplitPane>
      </div>
      <STDMCodeListTableProperties
        Show={!toggle}
        Hide={setToggle}
        Title={"Table Properties"}
      />
      <STDMCodeListFilter
        Show={!toggle1}
        Hide={setToggle1}
        codeList={codeList}
        setCodeList={setCodeList}
        studyId={studyId}
        getCDISCCodeLists={getCDISCCodeLists}
        Title={"Filter Value Label"}
      />
    </div>
  );
};

export default STDMCodeList;
