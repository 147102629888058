import React from "react";
import { Table } from "react-bootstrap";
import SplitPane from "react-split-pane";

const DatasetTable = () => {
  return (
    <>
      <SplitPane split="vertical" minSize={20} defaultSize="100%">
        <Table
          id="resizable-table"
          striped
          hover
          bordered
          className="m-0 custom-table"
        >
          <thead className="position-sticky" style={{ top: -1 }}>
            <tr>
              <th style={{ width: "200px" }}>Attribute</th>
              <th>Value</th>
              <th>Value</th>
              <th>Value</th>
              <th>Value</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>Dataset Name</td>
              <td className="text-capitalize"></td>
              <td className="text-capitalize"></td>
              <td className="text-capitalize"></td>
              <td className="text-capitalize"></td>
            </tr>
          </tbody>
        </Table>
      </SplitPane>
    </>
  );
};

export default DatasetTable;
