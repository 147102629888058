import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { FaPlusSquare } from "react-icons/fa";
import { sponserServices } from "../../../../APIServices/sponserServices";

const SponserAddProperties = ({
  getProjectList,
  projectName,
  setProjectName,
  selectedSponsorId,
  setSelectedSponsorId,
  clientdeatilsId,
}) => {
  const [statusMessage, setStatusMessage] = useState("");
  const [statusCode, setStatusCode] = useState(false);
  const [loader, setLoader] = useState(false);

  const handleSubmit = async (e) => {
    setLoader(true);
    e.preventDefault();
    let userData = {};
    userData.sponsorId = clientdeatilsId?._id;
    userData.projectName = projectName;
    let data = await sponserServices.addNewProject(userData);
    getProjectList();
    setStatusMessage(data);
    if (data?.statusCode === 200) {
      setLoader(false);
      setProjectName("");
      setStatusCode(true);
    }
    setTimeout(() => {
      setStatusMessage("");
    }, 3000);
  };

  const updateProject = async (e) => {
    setLoader(true);
    e.preventDefault();
    let userData = {};
    userData.id = selectedSponsorId;
    userData.projectName = projectName;
    let data = await sponserServices.updateProject(userData);
    getProjectList();
    setStatusMessage(data);
    if (data?.statusCode === 200) {
      setLoader(false);
      clearFormData();
      setProjectName("");
      setStatusCode(true);
      setSelectedSponsorId("");
    }
    setTimeout(() => {
      setStatusMessage("");
    }, 3000);
  };

  const clearFormData = () => {
    setProjectName("");
    setSelectedSponsorId("")
  };

  return (
    <>
      <Form.Group className="mb-2">
        <div className="hstack justify-content-between">
          <Form.Label className="mb-1 fw-bold">
            Project Name<span className="text-danger">*</span>
          </Form.Label>
          {selectedSponsorId && (
            <button
              className="fs10 br3 text-white border-primary bg-primary border-0"
              title="Add new Project"
              onClick={() => clearFormData()}
            >
              <FaPlusSquare style={{ marginBottom: 2 }} /> Add New
            </button>
          )}
        </div>
        <Form.Control
          required
          type="text"
          placeholder="Enter Project Name"
          value={projectName}
          onChange={(e) => setProjectName(e.target.value)}
        />
      </Form.Group>
      <Form.Group className="text-end">
        <button
          className="p-1 px-3 border rounded-1 text-white bg-color fs-13"
          onClick={!selectedSponsorId ? handleSubmit : updateProject}
        >
          <FaPlusSquare style={{ marginBottom: 2 }} />{" "}
          {!selectedSponsorId ? "Add" : "Update"} Project
        </button>
      </Form.Group>
    </>
  );
};

export default SponserAddProperties;
