import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { FaPlusSquare } from "react-icons/fa";
import { supplimentaryServices } from "../../../../APIServices/Supplementary/sdtmServices";
import { cdiscServices } from "../../../../APIServices/cdiscServices";
import Swal from "sweetalert2";

const AddSupplementary = ({
  getSupplimentalsList,
  supplimentaryData,
  setSupplimentaryData,
}) => {
  let [version, setVersion] = useState([]);
  let [versionId, setVersionId] = useState("");
  let [sdtmClass, setSDTMClass] = useState([]);
  let [order, setOrder] = useState("");
  let [classId, setClassId] = useState("");
  let [domain, setDomain] = useState([]);
  let [domainId, setDomainId] = useState("");
  let [variableName, setVariableName] = useState("");
  let [variableLabel, setVariableLabel] = useState("");
  let [variableType, setVariableType] = useState("");
  let [significant, setSignificant] = useState("");
  let [mandatory, setMandatory] = useState("");
  let [variableComment, setVariableComment] = useState([]);
  let [commentId, setCommentId] = useState("");
  let [variableMethod, setVariableMethod] = useState([]);
  let [codeListData, setCodeListData] = useState([]);
  let [methodId, setMethodId] = useState("");
  let [origin, setOrigin] = useState("");
  let [codeList, setCodeList] = useState("");

  const addNewSupplimental = async () => {
    let userData = {};
    userData.order = order;
    userData.versionId = versionId;
    userData.classId = classId;
    userData.domainId = domainId;
    userData.name = variableName;
    userData.label = variableLabel;
    userData.type = variableType;
    userData.mandatory = mandatory;
    userData.significant = significant;
    userData.commentId = commentId;
    userData.mathodId = methodId;
    userData.origin = origin;
    userData.codeList = codeList;
    let data = await supplimentaryServices.addNewSupplimental(userData);
    if (data?.statusCode === 200) {
      getSupplimentalsList();
    }
  };

  const updateSupplimentals = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Update this Suppliments?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {};
        userData.id = supplimentaryData._id;
        userData.order = order;
        userData.name = variableName;
        userData.label = variableLabel;
        userData.type = variableType;
        userData.mandatory = mandatory;
        userData.significant = significant;
        userData.commentId = commentId;
        userData.mathodId = methodId;
        userData.origin = origin;
        userData.codeList = codeList;
        let data = await supplimentaryServices.updateSupplimentals(userData);
        if (data?.statusCode === 200) {
          getSupplimentalsList();
          setSupplimentaryData("");
        }
      }
    });
  };

  useEffect(() => {
    setClassId(supplimentaryData?.classId);
    setVersionId(supplimentaryData?.versionId);
    setDomainId(supplimentaryData?.domainId);
    setOrder(supplimentaryData?.order);
    setVariableName(supplimentaryData?.name);
    setVariableLabel(supplimentaryData?.label);
    setVariableType(supplimentaryData?.dataType);
    setSignificant(supplimentaryData?.significant);
    setMandatory(supplimentaryData?.mandatory);
    setCommentId(supplimentaryData?.commentId);
    setMethodId(supplimentaryData?.mathodId);
    setOrigin(supplimentaryData?.origin);
    setCodeList(supplimentaryData?.codeList);
  }, [supplimentaryData]);

  const getActiveVersions = async () => {
    let data = await supplimentaryServices.getActiveVersions();
    setVersion(data?.data);
  };

  const getActiveClass = async (versionId) => {
    let data = await supplimentaryServices.getActiveClass(versionId);
    setSDTMClass(data?.data);
  };

  const getActiveDomains = async (classId) => {
    let data = await supplimentaryServices.getActiveDomains(classId);
    setDomain(data?.data);
  };

  const getSupplimentalCommentsList = async () => {
    let data = await cdiscServices.getSupplimentalCommentsList();
    setVariableComment(data?.data);
  };

  const getSupplimentalMathodsList = async () => {
    let data = await cdiscServices.getSupplimentalMathodsList();
    setVariableMethod(data?.data);
  };

  const getSTDMCodeList = async () => {
    let data = await supplimentaryServices.getClientLevelCodeList();
    setCodeListData(data?.data);
  };

  useEffect(() => {
    getActiveVersions();
    getActiveClass();
    getActiveDomains();
    getSupplimentalMathodsList();
    getSupplimentalCommentsList();
    getSTDMCodeList();
  }, []);

  const clearFormData = () => {
    setSupplimentaryData("");
    setClassId("");
    setVersionId("");
    setDomainId("");
    setOrder("");
    setVariableName("");
    setVariableLabel("");
    setVariableType("");
    setSignificant("");
    setMandatory("");
    setCommentId("");
    setMethodId("");
    setOrigin("");
    setCodeList("");
  };

  return (
    <Container fluid>
      <Row>
        <Form.Group as={Col} md="12" className="mb-2 p-0">
          <div className="hstack justify-content-between">
            <Form.Label className="fs-12 fw-bold mb-1">
              Order <span className="text-danger">*</span>
            </Form.Label>
            {supplimentaryData && (
              <button
                className="fs10 br3 text-white border-primary bg-primary border-0"
                title="Add new Method"
                onClick={() => clearFormData()}
              >
                <FaPlusSquare style={{ marginBottom: 2 }} /> Add New
              </button>
            )}
          </div>
          <Form.Control
            required
            type="number"
            value={order}
            style={{ fontSize: 11 }}
            onChange={(e) => setOrder(e.target.value)}
            placeholder="Enter Order No."
          />
        </Form.Group>
        {!supplimentaryData && (
          <>
            <Form.Group as={Col} md="12" className="mb-2 p-0">
              <Form.Label className="fs-12 fw-bold mb-1">
                Version <span className="text-danger">*</span>
              </Form.Label>
              <Form.Select
                style={{ fontSize: 11 }}
                onChange={(e) => {
                  setVersionId(e.target.value);
                  getActiveClass(e.target.value);
                }}
                value={versionId}
              >
                <option value="">Select Version</option>
                {version?.map((item, index) => (
                  <option
                    selected={item.versionId === item._id}
                    key={index}
                    value={item._id}
                  >
                    {item.version}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group as={Col} md="12" className="mb-2 p-0">
              <Form.Label className="fs-12 fw-bold mb-1">
                Class <span className="text-danger">*</span>
              </Form.Label>
              <Form.Select
                style={{ fontSize: 11 }}
                onChange={(e) => {
                  setClassId(e.target.value);
                  getActiveDomains(e.target.value);
                }}
                value={classId}
              >
                <option value="">Select Class</option>
                {sdtmClass?.map((item, index) => (
                  <option
                    selected={item.classId === item._id}
                    key={index}
                    value={item._id}
                  >
                    {item.class}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group as={Col} md="12" className="mb-2 p-0">
              <Form.Label className="fs-12 fw-bold mb-1">
                Domain <span className="text-danger">*</span>
              </Form.Label>
              <Form.Select
                style={{ fontSize: 11 }}
                onChange={(e) => setDomainId(e.target.value)}
                value={domainId}
              >
                <option value="">Select Domain</option>
                {domain?.map((item, index) => (
                  <option
                    selected={item.domainId === item._id}
                    key={index}
                    value={item._id}
                  >
                    {item.domain}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </>
        )}
        <Form.Group as={Col} md="12" className="mb-2 p-0">
          <Form.Label className="fs-12 fw-bold mb-1">
            Veriable Name <span className="text-danger">*</span>
          </Form.Label>
          <Form.Control
            required
            type="text"
            value={variableName}
            style={{ fontSize: 11 }}
            onChange={(e) => setVariableName(e.target.value)}
            placeholder="Enter Veriable Name"
          />
        </Form.Group>
        <Form.Group as={Col} md="12" className="mb-2 p-0">
          <Form.Label className="fs-12 fw-bold mb-1">
            Veriable Label <span className="text-danger">*</span>
          </Form.Label>
          <Form.Control
            required
            type="text"
            value={variableLabel}
            style={{ fontSize: 11 }}
            onChange={(e) => setVariableLabel(e.target.value)}
            placeholder="Enter Veriable Name"
          />
        </Form.Group>
        <Form.Group as={Col} md="12" className="mb-2 p-0">
          <Form.Label className="fs-12 fw-bold mb-1">
            Data Type <span className="text-danger">*</span>
          </Form.Label>
          <Form.Select
            style={{ fontSize: 11 }}
            onChange={(e) => setVariableType(e.target.value)}
          >
            <option value="">Select Type</option>
            <option selected={variableType === "text" && "text"} value="text">
              Text
            </option>
            <option
              selected={variableType === "integer" && "integer"}
              value="integer"
            >
              Integer
            </option>
            <option
              selected={variableType === "float" && "float"}
              value="float"
            >
              Float
            </option>
          </Form.Select>
        </Form.Group>
        {variableType === "float" && (
          <Form.Group as={Col} md="12" className="mb-2 p-0">
            <Form.Label className="fs-12 fw-bold mb-1">
              Significant <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              required
              type="number"
              value={significant}
              style={{ fontSize: 11 }}
              onChange={(e) => setSignificant(e.target.value)}
              placeholder="Enter Veriable Name"
            />
          </Form.Group>
        )}
        <Form.Group as={Col} md="12" className="mb-2 p-0">
          <Form.Label className="fs-12 fw-bold mb-1">
            Mandatory <span className="text-danger">*</span>
          </Form.Label>
          <Form.Select
            style={{ fontSize: 11 }}
            onChange={(e) => setMandatory(e.target.value)}
          >
            <option value="">Select Type</option>
            <option selected={mandatory === "yes" && "yes"} value="yes">
              Yes
            </option>
            <option selected={mandatory === "no" && "no"} value="no">
              No
            </option>
          </Form.Select>
        </Form.Group>
        <Form.Group as={Col} md="12" className="mb-2 p-0">
          <Form.Label className="fs-12 fw-bold mb-1">
            Origin <span className="text-danger">*</span>
          </Form.Label>
          <Form.Select
            style={{ fontSize: 11 }}
            onChange={(e) => setOrigin(e.target.value)}
          >
            <option value="">Select Origin</option>
            <option
              selected={origin === "Protocol" && "Protocol"}
              value="Protocol"
            >
              Protocol
            </option>
            <option
              selected={origin === "Assigned" && "Assigned"}
              value="Assigned"
            >
              Assigned
            </option>
            <option
              selected={origin === "Derived" && "Derived"}
              value="Derived"
            >
              Derived
            </option>
            <option selected={origin === "CRF" && "CRF"} value="CRF">
              CRF
            </option>
            <option selected={origin === "eDT" && "eDT"} value="eDT">
              eDT
            </option>
          </Form.Select>
        </Form.Group>
        <Form.Group as={Col} md="12" className="mb-2 p-0">
          <Form.Label className="fs-12 fw-bold mb-1">
            Variable Comment
          </Form.Label>
          <Form.Select
            style={{ fontSize: 11 }}
            onChange={(e) => {
              setCommentId(e.target.value);
            }}
            value={commentId}
            disabled={methodId}
          >
            <option value="">Select Variable Comment</option>
            {variableComment?.map((item, index) => (
              <option
                selected={item.commentId === item._id}
                key={index}
                value={item._id}
              >
                {item.commentId}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        <Form.Group as={Col} md="12" className="mb-2 p-0">
          <Form.Label className="fs-12 fw-bold mb-1">
            Variable Method
          </Form.Label>
          <Form.Select
            style={{ fontSize: 11 }}
            onChange={(e) => setMethodId(e.target.value)}
            value={methodId}
            disabled={commentId}
          >
            <option value="">Select Variable Method</option>
            {variableMethod?.map((item, index) => (
              <option
                selected={item.mathodId === item._id}
                key={index}
                value={item._id}
              >
                {item.mathodId}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        <Form.Group as={Col} md="12" className="mb-2 p-0">
          <Form.Label className="fs-12 fw-bold mb-1">
            Code List <span className="text-danger">*</span>
          </Form.Label>
          <Form.Select
            style={{ fontSize: 11 }}
            onChange={(e) => setCodeList(e.target.value)}
            value={codeList}
          >
            <option value="">Select Code List</option>
            {codeListData?.map((item, index) => (
              <option key={index} value={item?.codeList}>
                {item?.codeId}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </Row>
      <Form.Group className="text-end">
        <button
          className="p-1 px-3 border rounded-1 text-white bg-color fs-13 text-capitalize"
          disabled={!variableName}
          onClick={() =>
            supplimentaryData ? updateSupplimentals() : addNewSupplimental()
          }
        >
          <FaPlusSquare style={{ marginBottom: 2 }} />{" "}
          {!supplimentaryData ? "Add" : "Update"} Supplementary
        </button>
      </Form.Group>
    </Container>
  );
};

export default AddSupplementary;
