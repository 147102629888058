import React from "react";
import { Card, Form } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const SignUpSuccess = () => {
  return (
    <>
      <Card style={{ width: "30rem" }} className="border rounded-1 shadow-sm p-3">
        <Card.Body>
          <Card.Title className="fw-bold text-center mb-3">
            Thanks! your account has been successfully created.
          </Card.Title>
          <Card.Text>
            <center>
              <Form.Group className="mb-3">
                <Card.Text className="fs-13">
                  Please check your inbox, your <b>login credentials</b> is sent
                  on your <b>email</b>, which will be require for <b>Login </b>{" "}
                  your account.
                </Card.Text>
              </Form.Group>
              <Form.Group>
                <NavLink to={"/login"}>
                  <button
                    className={`w-50 hstack gap-1 justify-content-center tabButtons border rounded-1 fs-13 p-1`}
                  >
                    Back to Login
                  </button>
                </NavLink>
              </Form.Group>
            </center>
          </Card.Text>
        </Card.Body>
      </Card>
    </>
  );
};
export default SignUpSuccess;
