import React, { useEffect, useRef, useState } from "react";
import { Alert, Form, Spinner, Table } from "react-bootstrap";
import Swal from "sweetalert2";
import Moment from "react-moment";
import { useParams } from "react-router";
import SplitPane from "react-split-pane";
import { supplimentaryServices } from "../../../../APIServices/Supplementary/sdtmServices";
import Pagination from "../../../../Hooks/Pagination";
import { FaPlusSquare } from "react-icons/fa";

const ProtocolManagement = ({ tableRef, onMouseDown }) => {
  const ref = useRef(null);
  const { studyId } = useParams();
  const [name, setName] = useState("");
  const [label, setLabel] = useState("");
  const [value, setValue] = useState("");
  const [idData, setIdData] = useState("");
  const [type, setType] = useState("");
  const [protocolList, setProtocolList] = useState([]);
  const [responseMessage, setResponseMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const [tableloader, setTableLoader] = useState(false);
  const [statusCode, setStatusCode] = useState("");
  const [highlightedRow, setHighlightedRow] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = protocolList?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(protocolList?.length / itemsPerPage);

  const getProtocolsData = async () => {
    setTableLoader(true);
    let data = await supplimentaryServices.getProtocolsData(studyId);
    setProtocolList(data?.data || []);
    setTableLoader(false);
  };

  setTimeout(() => {
    setResponseMessage("");
    setStatusCode("");
  }, 10000);

  const addProtocol = async () => {
    setLoader(true);
    setTableLoader(true);
    let type = "post";
    let userData = { studyId, name, label, type, value };
    let data = await supplimentaryServices.addProtocol(userData);
    if (data?.statusCode === 200) {
      setName("");
      setLabel("");
      setValue("");
      getProtocolsData();
      setLoader(false);
      setTableLoader(false);
    } else {
      setLoader(false);
      setTableLoader(false);
    }
    setResponseMessage(data?.customMessage);
    setStatusCode(data?.statusCode);
  };

  const updateProtocolData = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Update this Protocol?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoader(true);
        setTableLoader(false);
        let userData = { id: idData, name, label, value };
        let data = await supplimentaryServices.updateProtocolData(userData);
        if (data?.statusCode === 200) {
          getProtocolsData();
          setLoader(false);
          setTableLoader(false);
          setName("");
          setLabel("");
          setValue("");
          setIdData("");
        } else {
          setLoader(false);
          setTableLoader(false);
        }
        setResponseMessage(data?.customMessage);
        setStatusCode(data?.statusCode);
      }
    });
  };

  const deleteProtocolData = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Delete this Protocol?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = await supplimentaryServices.deleteProtocolData(id);
        if (data?.statusCode === 200) {
          getProtocolsData();
          setTableLoader(false);
          if (idData === id) {
            setIdData("");
            setName("");
            setLabel("");
            setValue("");
          }
        } else {
          setTableLoader(false);
        }
      }
    });
  };

  const handleUpdateClick = (item) => {
    updateProtocol(
      item._id,
      item.parameterName,
      item.parameterLabel,
      item.parameterValue,
      item.type
    );
  };

  const updateProtocol = (id, name, label, value, type) => {
    setIdData(id);
    setName(name);
    setLabel(label);
    setValue(value);
    setType(type);
  };

  const addNewProtocol = () => {
    setIdData("");
    setType("");
    setName("");
    setLabel("");
    setValue("");
  };

  useEffect(() => {
    getProtocolsData();
  }, []);

  return (
    <SplitPane
      split="vertical"
      minSize={20}
      defaultSize={"20%"}
      style={{ height: "calc(100vh - 115px)" }}
    >
      <div>
        <Form.Group className="mb-2">
          <div className="hstack justify-content-between">
            <Form.Label className="fw-bold mb-1">
              Parameter Name <span className="text-danger">*</span>
            </Form.Label>
            {idData && (
              <button
                className="fs10 br3 text-white border-primary bg-primary border-0"
                title="Add new Protocol"
                onClick={() => addNewProtocol()}
              >
                <FaPlusSquare style={{ marginBottom: 2 }} /> Add New
              </button>
            )}
          </div>
          <Form.Control
            className="rounded-1"
            required
            type="text"
            disabled={type === "pre"}
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter Parameter Name"
          />
        </Form.Group>
        <Form.Group className="mb-2">
          <Form.Label className="fw-bold mb-1">
            Parameter Label <span className="text-danger">*</span>
          </Form.Label>
          <Form.Control
            className="rounded-1"
            required
            type="text"
            value={label}
            disabled={type === "pre"}
            onChange={(e) => setLabel(e.target.value)}
            placeholder="Enter Parameter Label"
          />
        </Form.Group>
        <Form.Group className="mb-2">
          <Form.Label className="fw-bold mb-1">
            Parameter Value <span className="text-danger">*</span>
          </Form.Label>
          <Form.Control
            className="rounded-1"
            required
            as="textarea"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            placeholder="Enter Parameter Value"
          />
          <div className="text-end my-2">
            <button
              className="p-1 px-3 border rounded-1 text-white bg-color fs-13 text-capitalize"
              onClick={idData ? updateProtocolData : addProtocol}
              disabled={!name || !label || loader}
            >
              {loader ? (
                <Spinner
                  className="me-1"
                  style={{ width: 15, height: 15 }}
                  animation="border"
                />
              ) : (
                <i
                  className={`fa-solid ${
                    idData ? "fa-edit" : "fa-circle-plus"
                  } me-1`}
                ></i>
              )}
              {idData ? "Update" : "Add"} Protocol
            </button>
          </div>
          {statusCode && (
            <Alert
              variant={statusCode === 200 ? "success" : "danger"}
              style={{
                fontSize: 11,
              }}
            >
              {responseMessage}
            </Alert>
          )}
        </Form.Group>
      </div>
      {tableloader ? (
        "Loading..."
      ) : result?.length === 0 || result?.length === undefined ? (
        "Protocol not found"
      ) : (
        <>
          <Table
            ref={tableRef}
            id="resizable-table"
            striped
            bordered
            className="custom-table"
            hover
          >
            <thead className="position-sticky z-1" style={{ top: -1 }}>
              <tr>
                <th
                  onMouseDown={(e) => onMouseDown(e, 0)}
                  style={{ width: 40 }}
                  className="text-center"
                >
                  Sr.
                </th>
                <th onMouseDown={(e) => onMouseDown(e, 1)}>Parameter Name</th>
                <th onMouseDown={(e) => onMouseDown(e, 2)}>Parameter Label</th>
                <th onMouseDown={(e) => onMouseDown(e, 3)}>Parameter Value</th>
                <th onMouseDown={(e) => onMouseDown(e, 5)}>
                  Created Date | Time
                </th>
                <th onMouseDown={(e) => onMouseDown(e, 6)}>
                  Update Date | Time
                </th>
                <th
                  style={{ width: 60 }}
                  onMouseDown={(e) => onMouseDown(e, 7)}
                >
                  <div className="text-center">Remove</div>
                </th>
              </tr>
            </thead>
            <tbody ref={ref}>
              {result?.map((item, index) => (
                <tr
                  className={`position-relative cursor-pointer ${
                    highlightedRow === index && "activeMenu"
                  }`}
                  onClick={() => setHighlightedRow(index)}
                  key={index}
                >
                  <td onClick={() => handleUpdateClick(item)}>
                    {(pageNumber - 1) * itemsPerPage + index + 1}
                  </td>
                  <td onClick={() => handleUpdateClick(item)}>
                    {item.parameterName}
                  </td>
                  <td onClick={() => handleUpdateClick(item)}>
                    {item.parameterLabel}
                  </td>
                  <td
                    title={item.parameterValue}
                    onClick={() => handleUpdateClick(item)}
                  >
                    {item.parameterValue}
                  </td>
                  <td onClick={() => handleUpdateClick(item)}>
                    <Moment format="DD MMM YYYY | HH:mm:ss">
                      {item.createdAt}
                    </Moment>
                  </td>
                  <td onClick={() => handleUpdateClick(item)}>
                    <Moment format="DD MMM YYYY | HH:mm:ss">
                      {item.updatedAt}
                    </Moment>
                  </td>
                  {item.type === "post" ? (
                    <td>
                      <div
                        className="text-center"
                        title="Delete Protocol"
                        onClick={() => deleteProtocolData(item._id)}
                      >
                        <i className="fa-solid fa-trash-can text-danger"></i>
                      </div>
                    </td>
                  ) : (
                    <td>
                      <div
                        className="text-center"
                        title="No Delete Pre-Define Prorocol"
                      >
                        <i className="fa-solid fa-trash-can text-secondary"></i>
                      </div>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
          <div className="position-sticky bottom-0">
            <Pagination
              itemsPerPage={itemsPerPage}
              setItemsPerPage={setItemsPerPage}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              totalPage={totalPage}
              totalItems={protocolList?.length}
            />
          </div>
        </>
      )}
    </SplitPane>
  );
};

export default ProtocolManagement;
