import React, { useEffect, useState } from "react";
import { Form, Offcanvas } from "react-bootstrap";
import { FaCheck, FaXmark } from "react-icons/fa6";
import { cdiscServices } from "../../../../APIServices/cdiscServices";

const WhereClausesFilter = ({
  Show,
  Hide,
  Title,
  whereClauseData,
  setWhereClauseData,
  studyId,
  getWhereClauseData,
}) => {
  const [domain, setDomain] = useState("");
  const [domainList, setDomainList] = useState([]);
  const filterValueLevelList = async () => {
    let userData = {};
    userData.studyId = studyId;
    userData.domainId = domain;
    let data = await cdiscServices.filterValueLevelList(userData);
    setWhereClauseData(data?.data || []);
    Hide();
  };

  const clearFilterData = () => {
    setDomain("");
    Hide();
    getWhereClauseData();
  };

  useEffect(() => {
    setDomainList(
      Array.from(
        new Map(
          whereClauseData?.map((item) => [
            item.domain + "_" + item.domainId,
            { domain: item.domain, domainId: item.domainId },
          ])
        ).values()
      )
    );
  }, [whereClauseData]);
  return (
    <>
      <Offcanvas show={Show} onHide={Hide} placement="end">
        <Offcanvas.Header>
          <Offcanvas.Title>{Title} </Offcanvas.Title>
          <div onClick={Hide} className="close_modal" title="Close">
            X
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0">
          <div
            className="pt-2 px-3 overflow-auto"
            style={{ height: "calc(100vh - 84px)" }}
          >
            <Form.Group className="mb-2">
              <Form.Label className="mb-1 fw-bold">Domain Id</Form.Label>
              <Form.Select onChange={(e) => setDomain(e.target.value)}>
                <option value="">Select Domain Id</option>
                {domainList?.map((item, index) => (
                  <option
                    selected={item.domainId === domain}
                    key={index}
                    value={item.domainId}
                  >
                    {item.domain}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </div>
          <div className="text-end py-2 px-3 shadow-sm bg-light w-100">
            <button
              className="py-1 px-3 border rounded-1 text-white bg-danger me-2 fs-13"
              onClick={() => clearFilterData()}
            >
              <FaXmark /> Clear
            </button>
            <button
              className="py-1 px-3 border rounded-1 text-white bg-color fs-13"
              onClick={() => filterValueLevelList()}
            >
              <FaCheck /> Apply
            </button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default WhereClausesFilter;
