import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { FaPlusSquare } from "react-icons/fa";
import { sponserServices } from "../../../../APIServices/sponserServices";
import { cdiscServices } from "../../../../APIServices/cdiscServices";

const SponserStudyData = ({
  getStudyList,
  setStudyName,
  studyName,
  studyDataId,
  setStudyDataId,
  projectData,
}) => {
  const [statusMessage, setStatusMessage] = useState("");
  const [version, setVersion] = useState("");
  const [CRFData, setCRFData] = useState("");
  const [versionList, setVersionList] = useState([]);
  const [statusCode, setStatusCode] = useState(false);
  const [loader, setLoader] = useState(false);

  const getActiveVersions = async () => {
    let data = await cdiscServices.getActiveVersions();
    setVersionList(data?.data);
  };

  const handleSubmit = async (e) => {
    setLoader(true);
    e.preventDefault();
    let userData = {};
    userData.projectId = projectData?._id;
    userData.studyName = studyName;
    userData.version = version;
    userData.crfData = CRFData;
    let data = await sponserServices.addNewStudy(userData);
    getStudyList();
    setStatusMessage(data);
    if (data?.statusCode === 200) {
      setLoader(false);
      setStudyName("");
      setStatusCode(true);
    }
    setTimeout(() => {
      setStatusMessage("");
    }, 3000);
  };

  const updateStudy = async (e) => {
    setLoader(true);
    e.preventDefault();
    let userData = {};
    userData.id = studyDataId;
    userData.studyName = studyName;
    let data = await sponserServices.updateStudy(userData);
    getStudyList();
    setStatusMessage(data);
    if (data?.statusCode === 200) {
      setLoader(false);
      setStudyName("");
      clearFormData("");
      setStatusCode(true);
      setStudyDataId("");
    }
    setTimeout(() => {
      setStatusMessage("");
    }, 3000);
  };

  const clearFormData = () => {
    setStudyName("");
    setStudyDataId("");
  };

  useEffect(() => {
    getActiveVersions();
  }, []);

  return (
    <>
      <Form.Group className="mb-2">
        <div className="hstack justify-content-between">
          <Form.Label className="mb-1 fw-bold">
            Study Name<span className="text-danger">*</span>
          </Form.Label>
          {studyDataId && (
            <button
              className="fs10 br3 text-white border-primary bg-primary border-0"
              title="Add new Study"
              onClick={() => clearFormData()}
            >
              <FaPlusSquare style={{ marginBottom: 2 }} /> Add New
            </button>
          )}
        </div>
        <Form.Control
          required
          type="text"
          placeholder="Enter Study Name"
          value={studyName}
          onChange={(e) => setStudyName(e.target.value)}
        />
      </Form.Group>
      {!studyDataId &&
        <>
          <Form.Group className="mb-2">
            <Form.Label className="mb-1 fw-bold">Version</Form.Label>
            <Form.Select
              onChange={(e) => setVersion(e.target.value)}
            >
              <option value="">Select Version</option>
              {versionList?.map((item, index) => (
                <option
                  selected={version === item._id}
                  key={index}
                  value={item._id}
                >
                  {item.version}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label className="mb-1 fw-bold">CRF Data</Form.Label>
            <Form.Select onClick={(e) => setCRFData(e.target.value)}>
              <option value="">Select CRF Data Type</option>
              <option selected={CRFData === "custom"} value="custom">
                Custom
              </option>
              <option
                selected={CRFData === "predefine"}
                value="predefine"
              >
                Predefine
              </option>
            </Form.Select>
          </Form.Group>
        </>
      }
      <Form.Group className="text-end">
        <button
          className="p-1 px-3 border rounded-1 text-white bg-color fs-13"
          onClick={!studyDataId ? handleSubmit : updateStudy}
        >
          <FaPlusSquare className="me-2" style={{ marginBottom: 2 }} />
          {!studyDataId ? "Add" : "Update"} Study
        </button>
      </Form.Group>
    </>
  );
};

export default SponserStudyData;
