import React, { useState } from "react";
import Swal from "sweetalert2";
import Moment from "react-moment";
import { Form, Table } from "react-bootstrap";
import Pagination from "../../../../Hooks/Pagination";
import { sponserServices } from "../../../../APIServices/sponserServices";

const SponserTableData = ({
  tableRef,
  setPhone,
  setCityID,
  setAddress,
  setStateID,
  setWebsite,
  onMouseDown,
  setCountryID,
  setShortName,
  setPostalCode,
  setIsFormOpen,
  addSponserData,
  setCompanyName,
  getSponsorsList,
  setIsOpenProperty,
  setCreateSponserId,
  setClientdeatilsId,
}) => {
  const [highlightedRow, setHighlightedRow] = useState(null);
  const deleteSponsors = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete sponser!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = {};
        let results = await sponserServices.deleteSponsors(id, data);
        if (results?.statusCode === 200) {
          getSponsorsList();
        }
      }
    });
  };

  const changeSponsorStatus = async (id, status) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Update sponser!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = {};
        data.id = id;
        data.status = status;
        let results = await sponserServices.changeSponsorStatus(data);
        if (results?.statusCode === 200) {
          getSponsorsList();
        }
      }
    });
  };

  const handleUpdateClick = (sponserData) => {
    setCreateSponserId(sponserData?._id);
    setCompanyName(sponserData.companyName);
    setShortName(sponserData.shortName);
    setWebsite(sponserData.website);
    setPhone(sponserData.phone);
    setAddress(sponserData.address);
    setCityID(sponserData.city_id);
    setStateID(sponserData.state_id);
    setCountryID(sponserData.country_id);
    setPostalCode(sponserData.postalCode);
    setIsFormOpen(true);
  };

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = addSponserData?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(addSponserData?.length / itemsPerPage);
  return (
    <>
      <Table
        tableRef={tableRef}
        id="resizable-table"
        className="text-nowrap mb-1 custom-table"
        hover
        bordered
        striped
      >
        <thead className="position-sticky z-1" style={{ top: -1 }}>
          <tr>
            <th onMouseDown={(e) => onMouseDown(e, 1)} style={{ width: 40 }}>
              Sr.
            </th>
            <th onMouseDown={(e) => onMouseDown(e, 2)}>Company Name</th>
            <th onMouseDown={(e) => onMouseDown(e, 3)}>Website</th>
            <th onMouseDown={(e) => onMouseDown(e, 4)}>Phone Number</th>
            <th onMouseDown={(e) => onMouseDown(e, 4)}>Status</th>
            <th onMouseDown={(e) => onMouseDown(e, 5)}>Added Date | Time</th>
            <th onMouseDown={(e) => onMouseDown(e, 7)} style={{ width: 80 }}>
              <div className="text-center">Update</div>
            </th>
            <th onMouseDown={(e) => onMouseDown(e, 8)} style={{ width: 60 }}>
              <div className="text-center">Remove</div>
            </th>
          </tr>
        </thead>
        <tbody>
          {result?.map((item, index) => (
            <tr
              key={index}
              className={`position-relative cursor-pointer ${
                highlightedRow === index && "activeMenu"
              }`}
              onClick={() => {
                setClientdeatilsId(item);
                setHighlightedRow(index);
              }}
            >
              <td>{(pageNumber - 1) * itemsPerPage + index + 1}</td>
              <td onClick={() => setIsOpenProperty(true)}>
                {item.companyName}
              </td>
              <td onClick={() => setIsOpenProperty(true)}>{item.website}</td>
              <td onClick={() => setIsOpenProperty(true)}>{item.phone}</td>
              <td className="p-0">
                <Form.Select
                  className={`border-0 rounded-0 bg-transparent ${
                    item?.status === "active" ? "text-success" : "text-danger"
                  }`}
                  style={{ fontSize: 12 }}
                  value={item.status}
                  onChange={(e) =>
                    changeSponsorStatus(item?._id, e.target.value)
                  }
                >
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </Form.Select>
              </td>
              <td onClick={() => setIsOpenProperty(true)}>
                <Moment format="DD MMM YYYY | HH:mm:ss">
                  {item.createdAt}
                </Moment>
              </td>
              <td className="text-center">
                <button
                  onClick={() => handleUpdateClick(item)}
                  title="Update"
                  className="text-white border-primary bg-primary border-0 fs-10 br3"
                >
                  Update
                </button>
              </td>
              <td>
                <div
                  className="text-center cursor-pointer"
                  title="Delete Sponser"
                  onClick={() => deleteSponsors(item?._id)}
                >
                  <i className={`fa-solid fa-trash-can CP red text-danger`}></i>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="position-sticky bottom-0">
        <Pagination
          totalPage={totalPage}
          pageNumber={pageNumber}
          itemsPerPage={itemsPerPage}
          totalItems={addSponserData?.length}
          setPageNumber={setPageNumber}
          setItemsPerPage={setItemsPerPage}
        />
      </div>
    </>
  );
};

export default SponserTableData;
