import React, { useEffect, useState } from "react";
import { Spinner, Table } from "react-bootstrap";
import Moment from "react-moment";
import { loginActivitiesServices } from "../../APIServices/loginActivitiesServices";
import { CiExport } from "react-icons/ci";
import Pagination from "../../Hooks/Pagination";

const FailedStatus = ({onMouseDown, tableRef}) => {
  const [failedLoginData, setFailedLoginData] = useState([]);
  const [loader, setLoader] = useState(false);
  const getFailedLoginRecord = async () => {
    setLoader(true);
    let data = await loginActivitiesServices.getFailedLoginRecord();
    let result = (data?.data || [])?.slice(0, 10);
    setFailedLoginData(result);
    setLoader(false);
  };
  useEffect(() => {
    getFailedLoginRecord();
  }, []);

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = failedLoginData?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(failedLoginData?.length / itemsPerPage);
  return (
    <>
      {loader ? (
        <div className="hstack justify-content-center h-75">
          <Spinner />
        </div>
      ) : failedLoginData?.length === 0 ? (
        <div className="hstack justify-content-center h-75">
          Data not found!
        </div>
      ) : (
        <>
          <div
            className="border-bottom text-end"
            style={{ padding: "3.2px 10px" }}
          >
            <button
              title="Export"
              className="tabButtons border rounded-1 fs-13"
              style={{ padding: "1px 5px" }}
            >
              <CiExport />
            </button>
          </div>
          <div className="mt-2 px-2 ">
            <div
              className="overflow-auto"
              style={{ maxHeight: "calc(100vh - 200px)" }}
            >
               <Table
                  tableRef={tableRef}
                  id="resizable-table"
                  className="text-nowrap mb-1 custom-table"
                  hover
                  bordered
                  striped
                >
                <thead className="position-sticky" style={{ top: -1 }}>
                  <tr>
                    <th onMouseDown={(e) => onMouseDown(e, 1)} style={{ width: 40 }}>Sr</th>
                    <th onMouseDown={(e) => onMouseDown(e, 2)}>IP Address</th>
                    <th onMouseDown={(e) => onMouseDown(e, 3)}>Time Zone</th>
                    <th onMouseDown={(e) => onMouseDown(e, 4)}>Location</th>
                    <th onMouseDown={(e) => onMouseDown(e, 5)}>Email</th>
                    <th onMouseDown={(e) => onMouseDown(e, 6)} style={{ width: 150 }}>Password</th>
                    <th onMouseDown={(e) => onMouseDown(e, 7)}>Activity Type</th>
                    <th onMouseDown={(e) => onMouseDown(e, 8)} style={{ width: 200 }}>Try Time</th>
                  </tr>
                </thead>
                <tbody>
                  {result?.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.ipAddress}</td>
                      <td>{item.timeZone}</td>
                      <td>{item.location}</td>
                      <td>{item.email}</td>
                      <td>{item.password}</td>
                      <td>{item.activities}</td>
                      <td>
                        <Moment format="DD MMM YYYY | HH:mm">
                          {item.createdAt}
                        </Moment>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            <Pagination
              totalPage={totalPage}
              pageNumber={pageNumber}
              itemsPerPage={itemsPerPage}
              totalItems={failedLoginData?.length}
              setPageNumber={setPageNumber}
              setItemsPerPage={setItemsPerPage}
            />
          </div>
        </>
      )}
    </>
  );
};

export default FailedStatus;
