import React, { useEffect, useState } from "react";
import { Spinner, Table } from "react-bootstrap";
import Moment from "react-moment";
import { loginActivitiesServices } from "../../../APIServices/loginActivitiesServices";
import { FaRegEye } from "react-icons/fa6";

const FailedStatus = ({ handleAddNewTab }) => {
  const [failedLoginData, setFailedLoginData] = useState([]);
  const [loader, setLoader] = useState(false);
  const getFailedLoginRecord = async () => {
    setLoader(true);
    let data = await loginActivitiesServices.getFailedLoginRecord();
    let result = (data?.data || [])?.slice(0, 10);
    setFailedLoginData(result);
    setLoader(false);
  };
  useEffect(() => {
    getFailedLoginRecord();
  }, []);

  return (
    <>
      {loader ? (
        <div className="hstack justify-content-center h-50">
          <Spinner />
        </div>
      ) : failedLoginData?.length === 0 ? (
        <div className="hstack justify-content-center h-50">
          Data not found!
        </div>
      ) : (
        <>
          <Table
            className="text-nowrap mb-1 custom-table"
            hover
            bordered
            striped
          >
            <thead>
              <tr>
                <th style={{ width: 40 }}>Sr</th>
                <th>IP Address</th>
                <th>Email</th>
                <th style={{ width: 150 }}>Password</th>
                <th style={{ width: 200 }}>Try Time</th>
              </tr>
            </thead>
            <tbody>
              {failedLoginData?.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td title={item.timeZone}>{item.ipAddress}</td>
                  <td>{item.email}</td>
                  <td>{item.password}</td>
                  <td>
                    <Moment format="DD MMM YYYY | HH:mm">
                      {item.createdAt}
                    </Moment>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {failedLoginData.length > 9 && (
            <div className="text-end">
              <button
                onClick={() =>
                  handleAddNewTab(
                    "Failed Status",
                    "fa-solid fa-ban",
                    "failedStatusId"
                  )
                }
                className={`p-1 px-3 border rounded-1 text-white bg-color fs-13`}
              >
                <FaRegEye style={{ marginBottom: 2 }} /> View All
              </button>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default FailedStatus;
