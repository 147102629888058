import React, { useEffect, useState } from "react";
import { Collapse } from "react-bootstrap";
import { CiFolderOn } from "react-icons/ci";
import { IoFolderOpenOutline } from "react-icons/io5";
import { VscChevronDown, VscChevronRight } from "react-icons/vsc";
import { HiOutlineBookOpen } from "react-icons/hi2";
import { supplimentaryServices } from "../APIServices/Supplementary/sdtmServices";

const ClientSidebar = ({ handleAddNewTab, tabs }) => {
  const [supplimemtry, setSupplimemtry] = useState(false);
  const [codeList, setCodeList] = useState(false);
  const [STDMList, setSTDMList] = useState([]);

  const getSTDMList = async () => {
    let data = await supplimentaryServices.getSTDMList();
    setSTDMList(data?.data || []);
  };

  useEffect(() => {
    getSTDMList();
  }, []);
  return (
    <>
      <div
        className="overflow-auto border rounded-1"
        style={{ height: "calc(100vh - 80px)" }}
      >
        <div
          className={`border-bottom cursor-pointer p-1 ${
            tabs.some(
              (item) => item.tabId === "sponser" && item.status === "active"
            ) && "activeMenu"
          }`}
          onClick={() =>
            handleAddNewTab("Sponser", "fa-solid fa-book-open", "sponser")
          }
        >
          <div className="hstack justify-content-between">
            <span>
              <HiOutlineBookOpen />
              <span className="ms-2 fs-13">Sponser</span>
            </span>
          </div>
        </div>
        <div
          className={`border-bottom cursor-pointer p-1 ${
            tabs.some(
              (item) =>
                item.tabId === "valueLabelData" && item.status === "active"
            ) && "activeMenu"
          }`}
          onClick={() =>
            handleAddNewTab(
              "Value Label Data",
              "fa-solid fa-book-open",
              "valueLabelData"
            )
          }
        >
          <div className="hstack justify-content-between">
            <span>
              <HiOutlineBookOpen />
              <span className="ms-2 fs-13">Value Label</span>
            </span>
          </div>
        </div>
        <div
          className={`text-truncate ${
            supplimemtry && "activeMenu"
          } border-bottom cursor-pointer p-1`}
          onClick={() => setSupplimemtry(!supplimemtry)}
          aria-expanded={supplimemtry}
        >
          <div className="hstack justify-content-between">
            <span>
              {supplimemtry ? <IoFolderOpenOutline /> : <CiFolderOn />}
              <span className="ms-2 fs-13">Supplementary</span>
            </span>
            <span>
              {supplimemtry ? <VscChevronDown /> : <VscChevronRight />}
            </span>
          </div>
        </div>

        <Collapse in={supplimemtry}>
          <div>
            <div
              className={`border-bottom cursor-pointer p-1 ps-3 ${
                tabs.some(
                  (item) =>
                    item.tabId === "sdtmDataMethod" && item.status === "active"
                ) && "activeMenu"
              }`}
              title="Method"
              onClick={() =>
                handleAddNewTab(
                  "Method",
                  "fa-solid fa-book-open",
                  "sdtmDataMethod"
                )
              }
            >
              <div className="hstack justify-content-between">
                <span>
                  <HiOutlineBookOpen />
                  <span className="ms-2 fs-13">Method</span>
                </span>
              </div>
            </div>
            <div
              className={`border-bottom cursor-pointer p-1 ps-3 ${
                tabs.some(
                  (item) =>
                    item.tabId === "sdtmDataComment" && item.status === "active"
                ) && "activeMenu"
              }`}
              title="Comment"
              onClick={() =>
                handleAddNewTab(
                  "Comment",
                  "fa-solid fa-book-open",
                  "sdtmDataComment"
                )
              }
            >
              <div className="hstack justify-content-between">
                <span>
                  <HiOutlineBookOpen />
                  <span className="ms-2 fs-13">Comment</span>
                </span>
              </div>
            </div>
            <div
              className={`border-bottom cursor-pointer p-1 ps-3 ${
                tabs.some(
                  (item) =>
                    item.tabId === "supplimentry" && item.status === "active"
                ) && "activeMenu"
              }`}
              title="Supplimentry"
              onClick={() =>
                handleAddNewTab(
                  "Supplementary",
                  "fa-solid fa-book-open",
                  "supplimentry"
                )
              }
            >
              <div className="hstack justify-content-between">
                <span>
                  <HiOutlineBookOpen />
                  <span className="ms-2 fs-13">Supplementary</span>
                </span>
              </div>
            </div>
            {/* <div
                            className={`border-bottom cursor-pointer p-1 ps-3 ${tabs.some(
                                (item) =>
                                    item.tabId === "codeListID" && item.status === "active"
                            ) && "activeMenu"
                                }`}
                            title="CodeList"
                            onClick={() =>
                                handleAddNewTab("CodeList", "fa-solid fa-book-open", "codeListID")
                            }
                        >
                            <div className="hstack justify-content-between">
                                <span>
                                    <HiOutlineBookOpen />
                                    <span className="ms-2 fs-13">CodeList</span>
                                </span>
                            </div>
                        </div> */}
          </div>
        </Collapse>
        <div
          className={`text-truncate ${
            codeList && "activeMenu"
          } border-bottom cursor-pointer p-1`}
          onClick={() => setCodeList(!codeList)}
          aria-expanded={codeList}
        >
          <div className="hstack justify-content-between">
            <span>
              {codeList ? <IoFolderOpenOutline /> : <CiFolderOn />}
              <span className="ms-2 fs-13">CodeList</span>
            </span>
            <span>{codeList ? <VscChevronDown /> : <VscChevronRight />}</span>
          </div>
        </div>

        <Collapse in={codeList}>
          <div>
            {STDMList?.map((item, index) => (
              <div
                key={index}
                className={`border-bottom cursor-pointer p-1 ps-3 ${
                  tabs.some(
                    (item) =>
                      item.tabId.includes("codeListID") &&
                      item.status === "active"
                  ) && "activeMenu"
                }
                `}
                title={item.name}
                onClick={() =>
                  handleAddNewTab(
                    `${item.name}`,
                    "fa-solid fa-book-open",
                    `codeListID_${item.uniqueId}`,
                    { id: item.uniqueId }
                  )
                }
              >
                <div className="hstack justify-content-between">
                  <span>
                    <HiOutlineBookOpen />
                    <span className="ms-2 fs-13"> {item.name}</span>
                  </span>
                </div>
              </div>
            ))}
          </div>
        </Collapse>
      </div>
    </>
  );
};

export default ClientSidebar;
