import React, { useEffect, useState } from "react";
import SplitPane from "react-split-pane";
import { Spinner } from "react-bootstrap";
import { AiOutlinePlus } from "react-icons/ai";
import { CgChevronDown } from "react-icons/cg";
import SponserListForm from "./SponserListForm";
import UseToggle from "../../../../Hooks/UseToggle";
import SponserTableData from "./SponserTableData";
import SponserStudyData from "./SponserStudyData";
import { BiCollapse, BiExpand } from "react-icons/bi";
import { CiFilter, CiViewTable } from "react-icons/ci";
import SponserAddProperties from "./SponserAddProperties";
import SponserStudytableData from "./SponserStudytableData";
import { TbLayoutSidebarRightExpand } from "react-icons/tb";
import SponserTableProperties from "./SponserTableProperties";
import SponserAttributeProperties from "./SponserAttributeProperties";
import { sponserServices } from "../../../../APIServices/sponserServices";
import SponserListPropertiesTableData from "./SponserListPropertiesTableData";

const SponserData = ({ onMouseDown, tableRef }) => {
  const [city, setCity] = useState([]);
  const [state, setState] = useState([]);
  const [phone, setPhone] = useState("");
  const { toggle, setToggle } = UseToggle();
  const [city_id, setCityID] = useState("");
  const [website, setWebsite] = useState("");
  const [address, setAddress] = useState("");
  const [Country, setCountry] = useState([]);
  const [loader, setLoader] = useState(false);
  const [state_id, setStateID] = useState("");
  const [studyName, setStudyName] = useState("");
  const [shortName, setShortName] = useState("");
  const [country_id, setCountryID] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [projectName, setProjectName] = useState("");
  const [officePhone, setOfficePhone] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [statusCode, setStatusCode] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [studyTableId, setStudyTableId] = useState("");
  const [studyDataId, setStudyDataId] = useState(null);
  const [addStudyData, setAddStudyData] = useState([]);
  const [statusMessage, setStatusMessage] = useState("");
  const [activeTabs, setActiveTabs] = useState("properties");
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isHalfScreen, setIsHalfScreen] = useState(false);
  const [addSponserData, setAddSponserData] = useState([]);
  const [addProjectData, setAddProjectData] = useState([]);
  const [isOpenProperty, setIsOpenProperty] = useState(false);
  const [createSponserId, setCreateSponserId] = useState(null);
  const [selectedSponsorId, setSelectedSponsorId] = useState(null);
  const [clientdeatilsId, setClientdeatilsId] = useState("");
  const [projectData, setProjectData] = useState("");

  const toggleHalfScreen = () => {
    setIsHalfScreen(!isHalfScreen);
  };

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  const getSponsorsList = async () => {
    let data = await sponserServices.getSponsorsList();
    setAddSponserData(data?.data);
  };

  const getProjectList = async () => {
    let data = await sponserServices.getProjectList(clientdeatilsId?._id);
    setAddProjectData(data?.data);
  };

  const getStudyList = async () => {
    let data = await sponserServices.getStudyList(projectData?._id);
    setAddStudyData(data?.data);
  };

  useEffect(() => {
    getSponsorsList();
  }, []);

  useEffect(() => {
    getProjectList();
  }, [clientdeatilsId]);

  useEffect(() => {
    getStudyList();
  }, [projectData]);

  const HeaderTools = [
    {
      title: isFormOpen ? "Hide Form" : "Add Sponser",
      icon: isFormOpen ? <TbLayoutSidebarRightExpand /> : <AiOutlinePlus />,
      onClick: () => setIsFormOpen(!isFormOpen),
    },
    {
      title: "View Table Properties",
      icon: <CiViewTable />,
      onClick: setToggle,
    },
    { title: "Filter", icon: <CiFilter /> },
  ];

  return (
    <div className={`code-list-container ${isFullScreen ? "full-screen" : ""}`}>
      <div
        className="hstack justify-content-between w-100 border-bottom"
        style={{ padding: "4.6px 9px" }}
      >
        <div className="hstack gap-2">
          {HeaderTools?.map((button, index) => (
            <React.Fragment key={index}>
              <button
                title={button.title}
                className="tabButtons border rounded-1 fs-13"
                style={{ padding: "1px 5px" }}
                onClick={button.onClick}
              >
                {button.icon}
              </button>
              {index < 2 && <div className="vr"></div>}
            </React.Fragment>
          ))}
        </div>
        <button
          className="tabButtons border rounded-1 fs-13"
          title={!isFullScreen ? "Full Mode" : "Original Mode"}
          onClick={toggleFullScreen}
        >
          {isFullScreen ? <BiCollapse /> : <BiExpand />}
        </button>
      </div>
      <div className="position-relative">
        <SplitPane
          split="horizontal"
          style={{
            height: isFullScreen ? "calc(100vh - 35px)" : "calc(100vh - 148px)",
          }}
          defaultSize={isOpenProperty ? "40%" : "100%"}
        >
          <SplitPane
            split="vertical"
            minSize={20}
            defaultSize={isFormOpen ? "20%" : "100%"}
          >
            {isFormOpen && (
              <SponserListForm
                getSponsorsList={getSponsorsList}
                companyName={companyName}
                setCompanyName={setCompanyName}
                shortName={shortName}
                setShortName={setShortName}
                website={website}
                setWebsite={setWebsite}
                phone={phone}
                loader={loader}
                setLoader={setLoader}
                setPhone={setPhone}
                phoneError={phoneError}
                setPhoneError={setPhoneError}
                postalCode={postalCode}
                setPostalCode={setPostalCode}
                setAddress={setAddress}
                address={address}
                officePhone={officePhone}
                setOfficePhone={setOfficePhone}
                city={city}
                setCity={setCity}
                city_id={city_id}
                setCityID={setCityID}
                state={state}
                setState={setState}
                state_id={state_id}
                setStateID={setStateID}
                Country={Country}
                setCountry={setCountry}
                country_id={country_id}
                setCountryID={setCountryID}
                statusMessage={statusMessage}
                setStatusMessage={setStatusMessage}
                statusCode={statusCode}
                setStatusCode={setStatusCode}
                setCreateSponserId={setCreateSponserId}
                createSponserId={createSponserId}
              />
            )}
            <div>
              {loader ? (
                <div className="hstack justify-content-center h-75">
                  <Spinner />
                </div>
              ) : addSponserData?.length === 0 ? (
                <div className="hstack justify-content-center h-75">
                  Data not found!
                </div>
              ) : (
                <SponserTableData
                  onMouseDown={onMouseDown}
                  tableRef={tableRef}
                  setIsFormOpen={setIsFormOpen}
                  getSponsorsList={getSponsorsList}
                  addSponserData={addSponserData}
                  setIsOpenProperty={setIsOpenProperty}
                  setCompanyName={setCompanyName}
                  setShortName={setShortName}
                  setWebsite={setWebsite}
                  setPhone={setPhone}
                  setAddress={setAddress}
                  setCityID={setCityID}
                  setStateID={setStateID}
                  setCountryID={setCountryID}
                  setPostalCode={setPostalCode}
                  setStatusCode={setStatusCode}
                  createSponserId={createSponserId}
                  setClientdeatilsId={setClientdeatilsId}
                  setCreateSponserId={setCreateSponserId}
                />
              )}
            </div>
          </SplitPane>
          {isOpenProperty && (
            <div
              className={`${isHalfScreen ? "full-screen" : ""}`}
              style={{ height: "100%" }}
            >
              <div
                className="hstack justify-content-between border-bottom px-2 pb-0 bg-light"
                style={{ padding: 5 }}
              >
                <div className="hstack me-1 gap-1">
                  {[
                    "properties",
                    "project",
                    `${projectData?.projectName || projectName} Studies`,
                  ].map((tab) =>
                    !projectData &&
                    tab ===
                      `${projectData?.projectName || projectName} Studies` ? (
                      ""
                    ) : (
                      <button
                        key={tab}
                        className={`buttonForTabs px-2 ${
                          activeTabs === tab ? "activee" : ""
                        }`}
                        onClick={() => setActiveTabs(tab)}
                        title={tab.charAt(0).toUpperCase() + tab.slice(1)}
                        style={{ borderRadius: "3px 3px 0px 0px" }}
                      >
                        {tab.charAt(0).toUpperCase() + tab.slice(1)}
                      </button>
                    )
                  )}
                </div>
                <div className="hstack gap-1">
                  <button
                    className={`tabButtons border-0 fs-13 px-2`}
                    style={{ borderRadius: "3px 3px 0px 0px" }}
                    title={"Hide"}
                    onClick={() => setIsOpenProperty(false)}
                  >
                    <CgChevronDown />
                  </button>
                  <button
                    className={`tabButtons border-0 fs-13 px-2`}
                    style={{ borderRadius: "3px 3px 0px 0px" }}
                    title={!isHalfScreen ? "Full Mode" : "Original Mode"}
                    onClick={toggleHalfScreen}
                  >
                    {isHalfScreen ? <BiCollapse /> : <BiExpand />}
                  </button>
                </div>
              </div>
              <SplitPane
                split="horizontal"
                className="position-relative"
                defaultSize="100%"
              >
                {activeTabs === "properties" ? (
                  <SponserAttributeProperties
                    clientdeatilsId={clientdeatilsId}
                    tableRef={tableRef}
                    onMouseDown={onMouseDown}
                  />
                ) : activeTabs === "project" ? (
                  <SplitPane split="vertical" minSize={20} defaultSize="20%">
                    <SponserAddProperties
                      projectName={projectName}
                      getProjectList={getProjectList}
                      setProjectName={setProjectName}
                      clientdeatilsId={clientdeatilsId}
                      selectedSponsorId={selectedSponsorId}
                      setSelectedSponsorId={setSelectedSponsorId}
                    />
                    <div>
                      {loader ? (
                        <div className="hstack justify-content-center h-75">
                          <Spinner />
                        </div>
                      ) : addProjectData?.length === 0 ? (
                        <div className="hstack justify-content-center h-75">
                          Data not found!
                        </div>
                      ) : (
                        <SponserListPropertiesTableData
                          getProjectList={getProjectList}
                          addProjectData={addProjectData}
                          onMouseDown={onMouseDown}
                          tableRef={tableRef}
                          setProjectName={setProjectName}
                          setSelectedSponsorId={setSelectedSponsorId}
                          setProjectData={setProjectData}
                        />
                      )}
                    </div>
                  </SplitPane>
                ) : activeTabs ===
                  `${projectData?.projectName || projectName} Studies` ? (
                  <SplitPane split="vertical" minSize={20} defaultSize="20%">
                    <SponserStudyData
                      getStudyList={getStudyList}
                      studyName={studyName}
                      setStudyName={setStudyName}
                      studyDataId={studyDataId}
                      setStudyDataId={setStudyDataId}
                      projectData={projectData}
                    />
                    <div>
                      {loader ? (
                        <div className="hstack justify-content-center h-75">
                          <Spinner />
                        </div>
                      ) : addStudyData?.length === 0 ? (
                        <div className="hstack justify-content-center h-75">
                          Data not found!
                        </div>
                      ) : (
                        <SponserStudytableData
                          onMouseDown={onMouseDown}
                          tableRef={tableRef}
                          addStudyData={addStudyData}
                          getStudyList={getStudyList}
                          setStudyName={setStudyName}
                          setStudyDataId={setStudyDataId}
                          setStudyTableId={setStudyTableId}
                          studyTableId={studyTableId}
                          studyDataId={studyDataId}
                        />
                      )}
                    </div>
                  </SplitPane>
                ) : (
                  ""
                )}
              </SplitPane>
            </div>
          )}
        </SplitPane>
      </div>
      <SponserTableProperties
        Show={!toggle}
        Hide={setToggle}
        Title={"Table Properties"}
      />
    </div>
  );
};

export default SponserData;
