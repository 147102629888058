import config from "../config/config.json";
import {
    handleResponse,
    headersWithAuth,
    headersWithAuthWithoutBody,
} from "../APIHelpers/Responses";
import { headers } from "../APIHelpers/Headers";

export const sponserServices = {
    addNewSponsor,
    addNewStudy,
    addNewProject,
    updateSponsors,
    updateProject,
    updateStudy,
    deleteSponsors,
    deleteProject,
    deleteStudy,
    getSponsorsList,
    getProjectList,
    getStudyList,
    findStudyDetails,
    changeSponsorStatus,
    changeProjectStatus,
    changeStudyStatus,
};

// Add Sponser
async function addNewSponsor(userData) {
    const response = await fetch(
        `${config.API_URL}addNewSponsor`,
        headersWithAuth("POST", userData, headers)
    );
    const data = await response.json();
    handleResponse(data);
    return data;
}

// Add Project
async function addNewProject(userData) {
    const response = await fetch(
        `${config.API_URL}addNewProject`,
        headersWithAuth("POST", userData, headers)
    );
    const data = await response.json();
    handleResponse(data);
    return data;
}

// Add Project
async function addNewStudy(userData) {
    const response = await fetch(
        `${config.API_URL}addNewStudy`,
        headersWithAuth("POST", userData, headers)
    );
    const data = await response.json();
    handleResponse(data);
    return data;
}


// Update Sponser
async function updateSponsors(userData) {
    const response = await fetch(
        `${config.API_URL}updateSponsors`,
        headersWithAuth("PUT", userData, headers)
    );
    const data = await response.json();
    await handleResponse(data);
    return data;
}

// Update Project
async function updateProject(userData) {
    const response = await fetch(
        `${config.API_URL}updateProject`,
        headersWithAuth("PUT", userData, headers)
    );
    const data = await response.json();
    await handleResponse(data);
    return data;
}

// Update Study
async function updateStudy(userData) {
    const response = await fetch(
        `${config.API_URL}updateStudy`,
        headersWithAuth("PUT", userData, headers)
    );
    const data = await response.json();
    await handleResponse(data);
    return data;
}

async function changeSponsorStatus(userData) {
    const response = await fetch(
        `${config.API_URL}changeSponsorStatus`,
        headersWithAuth("PUT", userData, headers)
    );
    const data = await response.json();
    await handleResponse(data);
    return data;
}

// Change Project Status 
async function changeProjectStatus(userData) {
    const response = await fetch(
        `${config.API_URL}changeProjectStatus`,
        headersWithAuth("PUT", userData, headers)
    );
    const data = await response.json();
    await handleResponse(data);
    return data;
}

// Change Study Status 
async function changeStudyStatus(userData) {
    const response = await fetch(
        `${config.API_URL}changeStudyStatus`,
        headersWithAuth("PUT", userData, headers)
    );
    const data = await response.json();
    await handleResponse(data);
    return data;
}

// Get All sponser for Table
async function getSponsorsList() {
    const response = await fetch(
        `${config.API_URL}getSponsorsList`,
        headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
}

// Get All project List for Table
async function getProjectList(sponsorId) {
    const response = await fetch(
        `${config.API_URL}getProjectList/${sponsorId}`,
        headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
}


async function getStudyList(projectId) {
    const response = await fetch(
        `${config.API_URL}getStudyList/${projectId}`,
        headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
}

async function findStudyDetails(studyId) {
    const response = await fetch(
        `${config.API_URL}findStudyDetails/${studyId}`,
        headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
}


// Delete Sponser
async function deleteSponsors(id) {
    const response = await fetch(
        `${config.API_URL}deleteSponsors/${id}`,
        headersWithAuthWithoutBody("DELETE", headers)
    );
    const data = await response.json();
    await handleResponse(data);
    return data;
}

// Delete Project
async function deleteProject(id) {
    const response = await fetch(
        `${config.API_URL}deleteProject/${id}`,
        headersWithAuthWithoutBody("DELETE", headers)
    );
    const data = await response.json();
    await handleResponse(data);
    return data;
}

// Delete Study
async function deleteStudy(id) {
    const response = await fetch(
        `${config.API_URL}deleteStudy/${id}`,
        headersWithAuthWithoutBody("DELETE", headers)
    );
    const data = await response.json();
    await handleResponse(data);
    return data;
}