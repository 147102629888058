import React, { useState } from 'react'
import { Form, Offcanvas } from 'react-bootstrap'
import { FaCheck } from 'react-icons/fa6'

const MethodsFilterData = ({ Show, Hide, Title, }) => {
    const [methodsName, setMethodsName] = useState("")

    return (
        <div>
            <Offcanvas show={Show} onHide={Hide} placement="end">
                <Offcanvas.Header>
                    <Offcanvas.Title>{Title} </Offcanvas.Title>
                    <div onClick={Hide} className="close_modal" title="Close">
                        X
                    </div>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form.Group className="mb-2">
                        <Form.Label className="mb-1 fw-bold">
                            Methods Name<span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                            required
                            type="text"
                            placeholder="Enter Methods Name"
                            value={methodsName}
                            onChange={(e) => setMethodsName(e.target.value)}
                        />
                    </Form.Group>
                    <div className="text-end my-2 pt-2">
                        <button
                            className="p-1 px-3 border rounded-1 text-white bg-color fs-13"
                        >
                            <FaCheck /> Apply
                        </button>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    )
}

export default MethodsFilterData