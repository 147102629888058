import React from "react";
import { Table } from "react-bootstrap";
import SplitPane from "react-split-pane";

const SponserAttributeProperties = ({
  clientdeatilsId,
  tableRef,
  onMouseDown,
}) => {
  return (
    <>
      <SplitPane split="vertical" minSize={20} defaultSize="100%">
        {clientdeatilsId ? (
          <div>
            <Table
              id="resizable-table"
              striped
              hover
              bordered
              className="m-0 custom-table"
              ref={tableRef}
            >
              <thead className="position-sticky" style={{ top: -1 }}>
                <tr>
                  <th
                    onMouseDown={(e) => onMouseDown(e, 0)}
                    style={{ width: "200px" }}
                  >
                    Attribute
                  </th>
                  <th onMouseDown={(e) => onMouseDown(e, 1)}>Value</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>Company Name</td>
                  <td className="text-capitalize">
                    {clientdeatilsId?.companyName}
                  </td>
                </tr>
                <tr>
                  <td>Short Name</td>
                  <td className="text-capitalize">
                    {clientdeatilsId?.shortName}
                  </td>
                </tr>
                <tr>
                  <td>Address</td>
                  <td className="text-capitalize">
                    {clientdeatilsId?.address}
                  </td>
                </tr>
                <tr>
                  <td>Phone</td>
                  <td>
                    <a
                      href={`${clientdeatilsId.phone && "tel:+"}${
                        clientdeatilsId.phone
                      }`}
                    >
                      {clientdeatilsId?.phone}
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>Website</td>
                  <td>
                    <a
                      href={`${
                        clientdeatilsId.website.startsWith("http")
                          ? ""
                          : "http://"
                      }${clientdeatilsId.website}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {clientdeatilsId.website}
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>Zip Code</td>
                  <td className="text-capitalize">
                    {clientdeatilsId?.zipCode}
                  </td>
                </tr>
                <tr>
                  <td>Country</td>
                  <td className="text-capitalize">
                    {clientdeatilsId?.countryId}
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        ) : (
          ""
        )}
      </SplitPane>
    </>
  );
};

export default SponserAttributeProperties;
