import React, { useEffect, useState } from "react";
import AddMethods from "./AddMethods";
import SplitPane from "react-split-pane";
import { Spinner } from "react-bootstrap";
import { AiOutlinePlus } from "react-icons/ai";
import MethodsTableData from "./MethodsTableData";
import UseToggle from "../../../../Hooks/UseToggle";
import MethodsFilterData from "./MethodsFilterData";
import { BiCollapse, BiExpand } from "react-icons/bi";
import { CiFilter, CiViewTable } from "react-icons/ci";
import { TbLayoutSidebarRightExpand } from "react-icons/tb";
import MethodsTableProperties from "./MethodsTableProperties";
import { cdiscServices } from "../../../../APIServices/cdiscServices";

const Methods = ({ onMouseDown, tableRef }) => {
  const [loader, setLoader] = useState(false);
  const { toggle, setToggle, toggle1, setToggle1 } = UseToggle();
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isOpenProperty, setIsOpenProperty] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [mathodId, setMathodId] = useState("");
  const [description, setDescription] = useState("");
  const [addNewMethodsdata, setaddNewMethodsdata] = useState([]);
  const [selectedMethodsId, setSelectedMethodsId] = useState(null);

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  const getSupplimentalMathodsList = async () => {
    let data = await cdiscServices.getSupplimentalMathodsList();
    setaddNewMethodsdata(data?.data);
  };

  const HeaderTools = [
    {
      title: isFormOpen ? "Hide Form" : "Add Methods",
      icon: isFormOpen ? <TbLayoutSidebarRightExpand /> : <AiOutlinePlus />,
      onClick: () => setIsFormOpen(!isFormOpen),
    },

    {
      title: "View Table Properties",
      icon: <CiViewTable />,
      onClick: setToggle,
    },
    { title: "Filter", icon: <CiFilter />, onClick: setToggle1 },
  ];

  useEffect(() => {
    getSupplimentalMathodsList();
  }, []);

  return (
    <div className={`code-list-container ${isFullScreen ? "full-screen" : ""}`}>
      <div
        className="hstack justify-content-between w-100 border-bottom"
        style={{ padding: "4.6px 9px" }}
      >
        <div className="hstack gap-2">
          {HeaderTools?.map((button, index) => (
            <React.Fragment key={index}>
              <button
                title={button.title}
                className="tabButtons border rounded-1 fs-13"
                style={{ padding: "1px 5px" }}
                onClick={button.onClick}
              >
                {button.icon}
              </button>
              {index < 2 && <div className="vr"></div>}
            </React.Fragment>
          ))}
        </div>
        <button
          className="tabButtons border rounded-1 fs-13"
          title={!isFullScreen ? "Full Mode" : "Original Mode"}
          onClick={toggleFullScreen}
        >
          {isFullScreen ? <BiCollapse /> : <BiExpand />}
        </button>
      </div>
      <div className="position-relative">
        <SplitPane
          split="horizontal"
          style={{
            height: isFullScreen ? "calc(100vh - 35px)" : "calc(100vh - 148px)",
          }}
          defaultSize={isOpenProperty ? "40%" : "100%"}
        >
          <SplitPane
            split="vertical"
            minSize={20}
            defaultSize={isFormOpen ? "20%" : "100%"}
          >
            {isFormOpen && (
              <AddMethods
                setMathodId={setMathodId}
                mathodId={mathodId}
                setDescription={setDescription}
                description={description}
                selectedMethodsId={selectedMethodsId}
                setSelectedMethodsId={setSelectedMethodsId}
                getSupplimentalMathodsList={getSupplimentalMathodsList}
              />
            )}
            <div>
              {loader ? (
                <div className="hstack justify-content-center h-75">
                  <Spinner />
                </div>
              ) : addNewMethodsdata?.length === 0 ? (
                <div className="hstack justify-content-center h-75">
                  Data not found!
                </div>
              ) : (
                <MethodsTableData
                  addNewMethodsdata={addNewMethodsdata}
                  setIsFormOpen={setIsFormOpen}
                  setMathodId={setMathodId}
                  setDescription={setDescription}
                  onMouseDown={onMouseDown}
                  tableRef={tableRef}
                  setSelectedMethodsId={setSelectedMethodsId}
                  getSupplimentalMathodsList={getSupplimentalMathodsList}
                />
              )}
            </div>
          </SplitPane>
        </SplitPane>
      </div>
      <MethodsTableProperties
        Show={!toggle}
        Hide={setToggle}
        Title={"Table Properties"}
      />
      <MethodsFilterData
        Show={!toggle1}
        Hide={setToggle1}
        Title={"Filter Methods"}
      />
    </div>
  );
};

export default Methods;
