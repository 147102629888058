import React, { useEffect, useRef, useState } from "react";
import { HiMiniChevronDown } from "react-icons/hi2";
import { TiHomeOutline } from "react-icons/ti";
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import {
  FailedStatus,
  LogsHistory,
  RecentActivities,
  SourceDataAnalytics,
  StudyDetails,
  StudyMetadata,
  StudyProtocol,
  TabHistory,
  UserDetails,
  SDTMSettings,
  Datasets,
  Variable,
  ValueLabel,
  WhereClauses,
  Dictionaries,
  Documents,
  StudySupplementary,
  ValueLavel,
  STDMCodeList,
  STDMMethod,
  STDMComment,
  Legacy,
  ACRF,
} from "./TabsComponent";
import Swal from "sweetalert2";
import Dashboard from "../Pages/Dashboard";
import DraggableTab from "./DraggableTab/DraggableTab";
import DnDContext from "./DraggableTab/DnDContext";
import Supplementary from "./TabsComponent/Supplementary/Supplementary/Supplementary";

const MainPanel = ({
  setTabs,
  tabs,
  handleAddNewTab,
  userCurrentData,
  currentUserData,
}) => {
  const [open, setOpen] = useState(false);
  const toggleRef = useRef(null);
  const tabContainerRef = useRef(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(tabs.length > 1);
  const handleActiveTab = (tabId) => {
    const updatedTabs = tabs.map((tab) => ({ ...tab, status: "inactive" }));
    updatedTabs.forEach((element) => {
      if (element.tabId === tabId) {
        element.status = "active";
      }
    });
    setTabs(updatedTabs);
  };

  const handleInactiveAllTab = () => {
    const updatedTabs = tabs.map((tab) => ({ ...tab, status: "inactive" }));
    setTabs(updatedTabs);
  };

  const handleDeleteTab = (tabId) => {
    // Swal.fire({
    //   title: "You want to Close this Tab",
    //   icon: "warning",
    //   showCancelButton: true,
    //   cancelButtonColor: "#d33",
    //   confirmButtonText: "Yes, close it!",
    // }).then(async (result) => {
    //   if (result.isConfirmed) {
    const updatedTabs = tabs.filter((tab) => tab.tabId !== tabId);
    setTabs(updatedTabs);
    //   }
    // });
  };

  const handleDeleteAllTab = () => {
    Swal.fire({
      title: "You want to Close all Tab",
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, close it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setTabs([]);
      }
    });
  };

  const handleCloseOtherTab = (item) => {
    Swal.fire({
      title: `You want to Close other Tab`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, close it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let updatedTabs = { ...item, status: "active" };
        setTabs([updatedTabs]);
      }
    });
  };

  const handleCloseRightTabs = (index) => {
    Swal.fire({
      title: `You want to Close all Right Tab`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, close it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const updatedTabs = tabs.slice(0, index + 1);
        const deletedArray = tabs.slice(index + 1);
        const checkStatus = deletedArray?.some(
          (item) => item.status === "active"
        );
        if (checkStatus) {
          updatedTabs[updatedTabs?.length - 1].status = "active";
          setTabs(updatedTabs);
        } else {
          setTabs(updatedTabs);
        }
      }
    });
  };

  const handleCloseLeftTabs = (index) => {
    Swal.fire({
      title: `You want to Close all left Tab`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, close it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const updatedTabs = tabs.slice(index);
        const deletedArray = tabs.slice(0, index);
        const checkStatus = deletedArray?.some(
          (item) => item.status === "active"
        );
        if (checkStatus) {
          updatedTabs[0].status = "active";
          setTabs(updatedTabs);
        } else {
          setTabs(updatedTabs);
        }
      }
    });
  };

  const handleMoveTab = (fromIndex, toIndex) => {
    setTabs((prevTabs) => {
      const updatedTabs = [...prevTabs];
      const [movedTab] = updatedTabs.splice(fromIndex, 1);
      updatedTabs.splice(toIndex, 0, movedTab);
      return updatedTabs;
    });
  };

  const handleMoveTabLeft = (index) => {
    if (index > 0) {
      handleMoveTab(index, index - 1);
    }
  };

  const handleMoveTabRight = (index) => {
    if (index < tabs.length - 1) {
      handleMoveTab(index, index + 1);
    }
  };

  const handleScrollTabLeft = () => {
    tabContainerRef?.current?.scrollBy({ left: -150, behavior: "smooth" });
    updateScrollState();
  };

  const handleScrollTabRight = () => {
    tabContainerRef?.current?.scrollBy({ left: 150, behavior: "smooth" });
    updateScrollState();
  };

  const updateScrollState = () => {
    const scrollLeft = tabContainerRef?.current?.scrollLeft;
    const scrollWidth = tabContainerRef?.current?.scrollWidth;
    const clientWidth = tabContainerRef?.current?.clientWidth;
    setCanScrollLeft(scrollLeft > 0);
    setCanScrollRight(scrollLeft < scrollWidth - clientWidth);
  };

  const activeTab = tabs.find((item) => item.status === "active");

  const [resizingColumn, setResizingColumn] = useState(null);
  const startX = useRef(0);
  const startWidth = useRef(0);
  const tableRef = useRef(null);

  useEffect(() => {
    const onMouseMove = (e) => {
      if (resizingColumn !== null) {
        const width = startWidth.current + (e.pageX - startX.current);
        const table = tableRef.current;

        if (!table) {
          console.error("Table reference is null.");
          return;
        }

        const th = table.querySelector(`th:nth-child(${resizingColumn + 1})`);

        if (!th) {
          console.error(
            `No th element found for column index: ${resizingColumn}`
          );
          return;
        }

        th.style.width = `${width}px`;
        table.classList.add("resizing");
      }
    };

    const onMouseUp = () => {
      setResizingColumn(null);
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };

    if (resizingColumn !== null) {
      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("mouseup", onMouseUp);
    }

    return () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };
  }, [resizingColumn]);

  const onMouseDown = (e, columnIndex) => {
    setResizingColumn(columnIndex);
    startX.current = e.pageX;
    startWidth.current = e.target.offsetWidth;
    document.body.style.userSelect = "none";
    tableRef?.current?.querySelectorAll("th")?.forEach((column, index) => {
      if (index !== columnIndex) {
        column.style.width = `${column.offsetWidth}px`;
      }
    });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (toggleRef?.current && !toggleRef?.current.contains(event.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <>
      <div
        className="overflow-auto border rounded-1 position-relative"
        style={{
          height:
            tabs?.length > 0 ? "calc(100vh - 80px)" : "calc(100vh - 78px)",
        }}
      >
        {tabs?.length > 0 && (
          <div
            ref={toggleRef}
            className="border-bottom tabbars hstack gap-2 px-2 pb-0 bg-light"
            style={{ padding: 7 }}
          >
            {tabs?.length > 0 && (
              <HiMiniChevronDown
                onClick={() => setOpen(!open)}
                className={open && "active"}
              />
            )}
            <TabHistory
              open={open}
              tabs={tabs}
              handleMoveTab={handleMoveTab}
              handleActiveTab={handleActiveTab}
              handleDeleteTab={handleDeleteTab}
              handleDeleteAllTab={handleDeleteAllTab}
            />
            {tabs?.length > 0 && (
              <TiHomeOutline
                className={!activeTab && "active"}
                onClick={handleInactiveAllTab}
              />
            )}
            {tabs?.length >= 8 && (
              <MdOutlineKeyboardArrowLeft
                className={canScrollLeft && "active"}
                onClick={handleScrollTabLeft}
              />
            )}
            <DnDContext>
              <div
                ref={tabContainerRef}
                onScroll={updateScrollState}
                className="hstack gap-2 w-100 overflow-auto blank"
              >
                {tabs?.map((item, index) => (
                  <DraggableTab
                    key={item.tabId}
                    item={item}
                    index={index}
                    handleMoveTab={handleMoveTab}
                    handleActiveTab={handleActiveTab}
                    handleDeleteTab={handleDeleteTab}
                    handleCloseOtherTab={handleCloseOtherTab}
                    handleCloseRightTabs={handleCloseRightTabs}
                    handleCloseLeftTabs={handleCloseLeftTabs}
                    handleDeleteAllTab={handleDeleteAllTab}
                    handleMoveTabLeft={handleMoveTabLeft}
                    handleMoveTabRight={handleMoveTabRight}
                  />
                ))}
              </div>
            </DnDContext>
            {tabs?.length >= 8 && (
              <MdOutlineKeyboardArrowRight
                className={canScrollRight && "active"}
                onClick={handleScrollTabRight}
              />
            )}
          </div>
        )}
        {activeTab?.tabId === "studyDetailsID" ? (
          <StudyDetails />
        ) : activeTab?.tabId === "studyMetadataID" ? (
          <StudyMetadata />
        ) : activeTab?.tabId === "studyProtocolID" ? (
          <StudyProtocol onMouseDown={onMouseDown} tableRef={tableRef} />
        ) : activeTab?.tabId === "stdmCodeListID" ? (
          <STDMCodeList onMouseDown={onMouseDown} tableRef={tableRef} />
        ) : activeTab?.tabId === "stdmMethodID" ? (
          <STDMMethod onMouseDown={onMouseDown} tableRef={tableRef} />
        ) : activeTab?.tabId === "sdtmCommentId" ? (
          <STDMComment onMouseDown={onMouseDown} tableRef={tableRef} />
        ) : activeTab?.tabId === "sourceDataAnalytics" ? (
          <SourceDataAnalytics onMouseDown={onMouseDown} tableRef={tableRef} />
        ) : activeTab?.tabId === "SDTMSettings" ? (
          <SDTMSettings onMouseDown={onMouseDown} tableRef={tableRef} />
        ) : activeTab?.tabId === "sdtmDataDatasets" ? (
          <Datasets onMouseDown={onMouseDown} tableRef={tableRef} />
        ) : activeTab?.tabId === "sdtmDataVeriable" ? (
          <Variable onMouseDown={onMouseDown} tableRef={tableRef} />
        ) : activeTab?.tabId === "sdtmDataValueLabel" ? (
          <ValueLabel onMouseDown={onMouseDown} tableRef={tableRef} />
        ) : activeTab?.tabId === "sdtmDataWhereClauses" ? (
          <WhereClauses onMouseDown={onMouseDown} tableRef={tableRef} />
        ) : activeTab?.tabId === "sdtmDataDictionaries" ? (
          <Dictionaries onMouseDown={onMouseDown} tableRef={tableRef} />
        ) : activeTab?.tabId === "sdtmDataACRF" ? (
          <ACRF onMouseDown={onMouseDown} tableRef={tableRef} />
        ) : activeTab?.tabId === "sdtmDataDocument" ? (
          <Documents onMouseDown={onMouseDown} tableRef={tableRef} />
        ) : activeTab?.tabId === "supplimentry" ? (
          <Supplementary onMouseDown={onMouseDown} tableRef={tableRef} />
        ) : activeTab?.tabId === "studySupplimentry" ? (
          <StudySupplementary onMouseDown={onMouseDown} tableRef={tableRef} />
        ) : activeTab?.tabId === "userId" ? (
          <UserDetails
            handleAddNewTab={handleAddNewTab}
            userCurrentData={userCurrentData}
            currentUserData={currentUserData}
          />
        ) : activeTab?.tabId === "recentActivityId" ? (
          <RecentActivities onMouseDown={onMouseDown} tableRef={tableRef} />
        ) : activeTab?.tabId === "historyId" ? (
          <LogsHistory onMouseDown={onMouseDown} tableRef={tableRef} />
        ) : activeTab?.tabId === "failedStatusId" ? (
          <FailedStatus onMouseDown={onMouseDown} tableRef={tableRef} />
        ) : activeTab?.tabId === "valueLavelId" ? (
          <ValueLavel onMouseDown={onMouseDown} tableRef={tableRef} />
        ) : activeTab?.tabId === "legacyID" ? (
          <Legacy onMouseDown={onMouseDown} tableRef={tableRef} />
        ) : (
          <Dashboard userCurrentData={userCurrentData} tabs={tabs} />
        )}
      </div>
    </>
  );
};

export default MainPanel;
