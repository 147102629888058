import React, { useEffect, useState } from "react";
import {
  Badge,
  Col,
  Container,
  Form,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { supplimentaryServices } from "../../../../APIServices/Supplementary/sdtmServices";
import { useParams } from "react-router-dom";

const StudyDetails = () => {
  const { studyId } = useParams();
  const [essentialData, setEssentialData] = useState([]);
  const [additionalData, setAdditionalData] = useState([]);
  const [loader, setLoader] = useState(false);
    const getProtocolsData = async () => {
      setLoader(true);
      let data = await supplimentaryServices.getProtocolsData(studyId);
      setEssentialData(data?.data?.filter((item) => item.type === "pre"));
      setAdditionalData(data?.data?.filter((item) => item.type === "post"));
      setLoader(false);
    };
    useEffect(() => {
      getProtocolsData();
    }, []);
  return (
    <>
      <Container
        fluid
        className="overflow-auto"
        style={{ height: "calc(100vh - 115px)" }}
      >
        <>
          <Row>
            <Form.Group as={Col} md="12" lg="12" sm="12" className="px-1">
              <div className="shadow-md border my-2 mx-1 rounded-1 overflow-hidden">
                <Form.Group className="border-bottom pb-2 p-2 hstack justify-content-between bg-light">
                  <Form.Label className="m-0 text-nowrap fw-bold fs-13">
                    Study Overview
                  </Form.Label>
                </Form.Group>
                <div className="p-2">
                  <Form.Group className="mb-2">
                    <Form.Label className="m-0 text-nowrap">
                      <b>Study Number :</b> IMN-1000-21
                    </Form.Label>
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <Form.Label className="m-0 text-nowrap">
                      <b>Study Title :</b> {essentialData[2]?.parameterValue}
                    </Form.Label>
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <Form.Label className="m-0 text-nowrap">
                      <b>Active Study Version :</b> 3.0 | 12-june-2024
                    </Form.Label>
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <Form.Label className="m-0 text-nowrap">
                      <b>Study Status :</b> <Badge bg="success">Active</Badge>
                    </Form.Label>
                  </Form.Group>
                </div>
              </div>
            </Form.Group>
            <Form.Group as={Col} md="12" lg="12" sm="12" className="px-1">
              <div className="shadow-md border my-2 mx-1 rounded-1 overflow-hidden">
                <Form.Group className="border-bottom pb-2 p-2 hstack justify-content-between bg-light">
                  <Form.Label className="m-0 text-nowrap fw-bold fs-13">
                    Study Essential
                  </Form.Label>
                </Form.Group>
                <div className="d-flex gap-3 p-2" style={{ flexFlow: "wrap" }}>
                  {loader ? (
                    <div className="hstack justify-content-center h-100 w-100 py-5">
                      <Spinner />
                    </div>
                  ) : essentialData?.length === 0 ? (
                    <div className="hstack justify-content-center h-100 w-100 py-5 fs-13">
                      Study Essential not found!
                    </div>
                  ) : (
                    <Table className="custom-table">
                      <tbody>
                        {essentialData?.map((item) =>
                          item.parameterName === "TITLE" ? (
                            ""
                          ) : (
                            <tr>
                              <td style={{ width: 200 }}>
                                <b>{item.parameterLabel} : </b>
                              </td>
                              <td>
                                <div style={{ textWrap: "balance" }}>
                                  {item.parameterValue
                                    ? item.parameterValue
                                    : "N/A"}
                                </div>
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </Table>
                  )}
                </div>
              </div>
            </Form.Group>
            {additionalData.length > 0 && (
              <Form.Group as={Col} md="12" lg="12" sm="12" className="px-1">
                <div className="shadow-md border my-2 mx-1 rounded-1 overflow-hidden">
                  <Form.Group className="border-bottom pb-2 p-2 hstack justify-content-between bg-light">
                    <Form.Label className="m-0 text-nowrap fw-bold fs-13">
                      Additional Details
                    </Form.Label>
                  </Form.Group>
                  <div
                    className="d-flex gap-3 p-2"
                    style={{ flexFlow: "wrap" }}
                  >
                    {loader ? (
                      <div className="hstack justify-content-center h-100 w-100 py-5">
                        <Spinner />
                      </div>
                    ) : additionalData?.length === 0 ? (
                      <div className="hstack justify-content-center h-100 w-100 py-5 fs-13">
                        Additional Details not found!
                      </div>
                    ) : (
                      <Table className="custom-table">
                        <tbody>
                          {additionalData?.map((item) => (
                            <tr>
                              <td style={{ width: 200 }}>
                                <b>{item.parameterLabel} : </b>
                              </td>
                              <td>
                                <div style={{ textWrap: "balance" }}>
                                  {item.parameterValue
                                    ? item.parameterValue
                                    : "N/A"}
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    )}
                  </div>
                </div>
              </Form.Group>
            )}
          </Row>
        </>
      </Container>
    </>
  );
};

export default StudyDetails;
