import React, { useState } from "react";
import { Table } from "react-bootstrap";
import Pagination from "../../../../Hooks/Pagination";
import { supplimentaryServices } from "../../../../APIServices/Supplementary/sdtmServices";
import Swal from "sweetalert2";

const CodeListTableData = ({
  codeList,
  getSTDMCodeData,
  STDMId,
  onMouseDown,
  tableRef,
  handleUpdateClick,
  setIsFormOpen,
}) => {
  const [highlightedRow, setHighlightedRow] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = codeList?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(codeList?.length / itemsPerPage);

  const deleteClientCodeList = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete Code List!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let results = await supplimentaryServices.deleteClientCodeList(id);
        if (results?.statusCode === 200) {
          getSTDMCodeData(STDMId);
        }
      }
    });
  };

  return (
    <>
      <Table
        id="resizable-table"
        className="m-0 custom-table"
        bordered
        hover
        striped
        ref={tableRef}
      >
        <thead className="position-sticky z-1" style={{ top: -1 }}>
          <tr>
            <th
              onMouseDown={(e) => onMouseDown(e, 0)}
              style={{ width: 70 }}
              className="text-center"
            >
              Order
            </th>
            <th onMouseDown={(e) => onMouseDown(e, 1)}>CodeList</th>
            <th onMouseDown={(e) => onMouseDown(e, 2)}>CodeName</th>
            <th onMouseDown={(e) => onMouseDown(e, 3)}>Type</th>
            <th onMouseDown={(e) => onMouseDown(e, 4)}>Data Type</th>
            <th onMouseDown={(e) => onMouseDown(e, 5)}>Terms</th>
            <th onMouseDown={(e) => onMouseDown(e, 6)}>Decode Value</th>
            <th style={{ width: 70 }} onMouseDown={(e) => onMouseDown(e, 7)}>
              Update
            </th>
            <th style={{ width: 70 }} onMouseDown={(e) => onMouseDown(e, 8)}>
              Delete
            </th>
          </tr>
        </thead>
        <tbody>
          {result?.map((item, index) => (
            <tr
              className={`position-relative cursor-pointer ${
                highlightedRow === index && "activeMenu"
              }`}
              onClick={() => setHighlightedRow(index)}
              key={index}
            >
              <td className="text-center">{item?.order}</td>
              <td>{item?.codeId}</td>
              <td>{item?.codeName}</td>
              <td className="text-capitalize">{item?.type}</td>
              <td className="text-capitalize">{item?.dataType}</td>
              <td>{item?.terms}</td>
              <td>{item?.decodeValue}</td>
              <td className="text-center">
                <button
                  onClick={() => {
                    handleUpdateClick(item);
                    setIsFormOpen(true);
                  }}
                  title="Update"
                  className="text-white border-primary bg-primary border-0 fs-10 br3"
                >
                  Update
                </button>
              </td>
              <td>
                <div
                  className="text-center cursor-pointer"
                  title="Delete CodeList"
                  onClick={() => deleteClientCodeList(item?._id)}
                >
                  <i className={`fa-solid fa-trash-can text-danger`}></i>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="position-sticky bottom-0">
        <Pagination
          totalPage={totalPage}
          pageNumber={pageNumber}
          itemsPerPage={itemsPerPage}
          totalItems={codeList?.length}
          setPageNumber={setPageNumber}
          setItemsPerPage={setItemsPerPage}
        />
      </div>
    </>
  );
};

export default CodeListTableData;
