import React, { useState } from "react";
import Swal from "sweetalert2";
import { Form, Table } from "react-bootstrap";
import Pagination from "../../../../Hooks/Pagination";
import { cdiscServices } from "../../../../APIServices/cdiscServices";

const CommentsTableData = ({
  addNewCommentsdata,
  tableRef,
  onMouseDown,
  setDescription,
  setCommentId,
  setSelectedCommentsId,
  getSupplimentalCommentsList,
  setIsFormOpen,
}) => {
  const [highlightedRow, setHighlightedRow] = useState(null);
  const changeSupplimentalCommentsStatus = async (id, status) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Update Comment!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = {};
        data.id = id;
        data.status = status;
        let results = await cdiscServices.changeSupplimentalCommentsStatus(
          data
        );
        if (results?.statusCode === 200) {
          getSupplimentalCommentsList();
        }
      }
    });
  };

  const deleteSupplimentalComments = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete Comment!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = {};
        let results = await cdiscServices.deleteSupplimentalComments(id, data);
        if (results?.statusCode === 200) {
          getSupplimentalCommentsList();
        }
      }
    });
  };

  const handleUpdateClick = (comments) => {
    setSelectedCommentsId(comments?._id);
    setCommentId(comments?.commentId);
    setDescription(comments?.description);
    setIsFormOpen(true);
  };

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = addNewCommentsdata?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(addNewCommentsdata?.length / itemsPerPage);
  return (
    <>
      <Table
        tableRef={tableRef}
        id="resizable-table"
        className="text-nowrap mb-1 custom-table"
        hover
        bordered
        striped
      >
        <thead className="position-sticky z-1" style={{ top: -1 }}>
          <tr>
            <th onMouseDown={(e) => onMouseDown(e, 1)} style={{ width: 40 }}>
              Sr.
            </th>
            <th onMouseDown={(e) => onMouseDown(e, 2)}>Comment</th>
            <th onMouseDown={(e) => onMouseDown(e, 3)}>Description</th>
            <th onMouseDown={(e) => onMouseDown(e, 4)} style={{ width: 80 }}>
              <div className="text-center">Status</div>
            </th>
            <th onMouseDown={(e) => onMouseDown(e, 5)} style={{ width: 80 }}>
              <div className="text-center">Update</div>
            </th>
            <th onMouseDown={(e) => onMouseDown(e, 6)} style={{ width: 60 }}>
              <div className="text-center">Delete</div>
            </th>
          </tr>
        </thead>
        <tbody>
          {result.map((item, index) => (
            <tr
              key={index}
              className={`position-relative ${
                highlightedRow === index && "activeMenu"
              }`}
              onClick={() => setHighlightedRow(index)}
            >
              <td>{(pageNumber - 1) * itemsPerPage + index + 1}</td>
              <td>{item.commentId}</td>
              <td>{item.description}</td>
              <td className="p-0">
                <Form.Select
                  className={`border-0 rounded-0 bg-transparent ${
                    item?.status === "active" ? "text-success" : "text-danger"
                  }`}
                  style={{ fontSize: 12 }}
                  value={item.status}
                  onChange={(e) =>
                    changeSupplimentalCommentsStatus(item?._id, e.target.value)
                  }
                >
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </Form.Select>
              </td>
              <td className="text-center">
                <button
                  onClick={() => handleUpdateClick(item)}
                  title="Update"
                  className="text-white border-primary bg-primary border-0 fs-10 br3"
                >
                  Update
                </button>
              </td>
              <td>
                <div
                  className="text-center cursor-pointer"
                  title="Delete Cpmment"
                  onClick={() => deleteSupplimentalComments(item?._id)}
                >
                  <i
                    title="Delete Methods"
                    className={`fa-solid fa-trash-can CP red text-danger`}
                  ></i>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="position-sticky bottom-0">
        <Pagination
          totalPage={totalPage}
          pageNumber={pageNumber}
          itemsPerPage={itemsPerPage}
          totalItems={addNewCommentsdata?.length}
          setPageNumber={setPageNumber}
          setItemsPerPage={setItemsPerPage}
        />
      </div>
    </>
  );
};

export default CommentsTableData;
