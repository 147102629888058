import React, { useEffect, useState } from "react";
import { Form, Offcanvas } from "react-bootstrap";
import { FaCheck, FaXmark } from "react-icons/fa6";
import { cdiscServices } from "../../../../APIServices/cdiscServices";

const STDMCodeListFilter = ({
  Show,
  Hide,
  Title,
  codeList,
  setCodeList,
  studyId,
  getCDISCCodeLists,
}) => {
  const [id, setId] = useState("");
  const [idList, setIdList] = useState([]);
  const filterValueLevelList = async () => {
    let userData = {};
    userData.studyId = studyId;
    userData.codeId = id;
    let data = await cdiscServices.filterValueLevelList(userData);
    setCodeList(data?.data || []);
    Hide();
  };

  const clearFilterData = () => {
    setId("");
    Hide();
    getCDISCCodeLists();
  };

  useEffect(() => {
    setIdList(
      Array.from(
        new Map(
          codeList?.map((item) => [
            item.id + "_" + item.codeId,
            { id: item.id, codeId: item.codeId },
          ])
        ).values()
      )
    );
  }, [codeList]);
  return (
    <>
      <Offcanvas show={Show} onHide={Hide} placement="end">
        <Offcanvas.Header>
          <Offcanvas.Title>{Title} </Offcanvas.Title>
          <div onClick={Hide} className="close_modal" title="Close">
            X
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0">
          <div
            className="pt-2 px-3 overflow-auto"
            style={{ height: "calc(100vh - 84px)" }}
          >
            <Form.Group className="mb-2">
              <Form.Label className="mb-1 fw-bold">Id</Form.Label>
              <Form.Select onChange={(e) => setId(e.target.value)}>
                <option value="">Select Id</option>
                {idList?.map((item, index) => (
                  <option
                    selected={item.codeId === id}
                    key={index}
                    value={item.codeId}
                  >
                    {item.codeId}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </div>
          <div className="text-end py-2 px-3 shadow-sm bg-light w-100">
            <button
              className="py-1 px-3 border rounded-1 text-white bg-danger me-2 fs-13"
              onClick={() => clearFilterData()}
            >
              <FaXmark /> Clear
            </button>
            <button
              className="py-1 px-3 border rounded-1 text-white bg-color fs-13"
              onClick={() => filterValueLevelList()}
            >
              <FaCheck /> Apply
            </button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default STDMCodeListFilter;
