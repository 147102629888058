import React from "react";
import { Container } from "react-bootstrap";

const Footer = () => {
  return (
    <Container fluid expand="lg" className="bg-color p-1 px-2">
      <div className="hstack justify-content-between w-100">
        <p className="text-white m-0">Galax® CDISC™</p>
        <p className="text-white m-0">v1.0.0</p>
      </div>
    </Container>
  );
};

export default Footer;
