import React from "react";
import { Outlet, Navigate } from "react-router";
import { getStore } from "../Utils/UniversalFunction";
import { ChangePassword, OtpVerification } from "../Component";

const auth = getStore("token");
let currentRole = JSON.parse(localStorage.getItem("currentRole"));
const currentUrl = window.location.href;
const splitUrl = currentUrl?.split("/");
const currentPath = splitUrl[3];
const DashboardPermission = ({
  twoFA,
  authType,
  email,
  currentUserData,
  passwordUpdate,
}) => {
  if (auth) {
    return twoFA ? (
      <OtpVerification
        authType={authType}
        currentUserData={currentUserData}
        email={email}
      />
    ) : !passwordUpdate ? (
      <ChangePassword currentUserData={currentUserData} />
    ) : currentRole === "cdisc" ? (
      <Outlet />
    ) : (
      ""
    );
  } else {
    return (window.location = "/");
  }
};

const LoginPermission = () => {
  if (!auth) {
    if (currentPath === "login") {
      return <Outlet to="/login" />;
    } else if (currentPath === "forget-password") {
      return <Outlet to="/forget-password" />;
    } else if (currentPath === "otp-verification") {
      return <Outlet to="/otp-verification" />;
    } else if (currentPath === "change-password") {
      return <Outlet to="/change-password" />;
    } else if (currentPath.split("=")[0] === "reset-password?token") {
      return <Outlet to="/reset-password" />;
    } else if (currentPath === "signup") {
      return <Outlet to="/signup" />;
    } else if (currentPath === "") {
      return <Outlet to="/" />;
    }
  } else {
    if (
      currentPath === "" ||
      currentPath === "login" ||
      currentPath === "forget-password" ||
      currentPath === "reset-password" ||
      currentPath === "otp-verification" ||
      currentPath === "change-password" ||
      currentPath === "signup"
    ) {
      return <Navigate to="/home" />;
    }
  }
};

const StudyPermission = ({
  twoFA,
  authType,
  email,
  currentUserData,
  passwordUpdate,
}) => {
  if (auth) {
    return twoFA ?
      <OtpVerification
        authType={authType}
        currentUserData={currentUserData}
        email={email}
      /> :
      (!passwordUpdate ? (
        <ChangePassword />
      ) : currentRole === "study" ? (
        <Outlet />
      ) : (
        ""
      ));
  } else {
    return <Navigate to="/login" />;
  }
};

export const Permission = {
  LoginPermission,
  DashboardPermission,
  StudyPermission
};
