import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { LuImport } from "react-icons/lu";

const ImportLegacy = () => {
  const [selectFormat, setSelectFormat] = useState("");
  const [uploadFile, setUploadFile] = useState(null);
  const [fileDatasetName, setFileDatasetName] = useState("");
  const [fileDatasetLabel, setFileDatasetLabel] = useState("");
  const [fileColumnName, setFileColumnName] = useState("");
  const [fileColumnLabel, setFileColumnLabel] = useState("");

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const csvType = file.type === "text/csv";
    const excelType =
      file.type === "application/vnd.ms-excel" ||
      file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    if (
      (selectFormat === "csv" && csvType) ||
      (selectFormat === "excel" && excelType)
    )
      setUploadFile(file);
    else {
      setUploadFile(null);
      alert(`Please select a ${selectFormat.toUpperCase()} file.`);
    }
  };

  //   const handleImageChange = async () => {
  //     if (uploadFile) {
  //       const formData = new FormData();
  //       formData.append("file", uploadFile);
  //       formData.append("fileName", fileTitle);
  //       let result = await cdiscServices.uploadSTDMTerminology(formData);
  //       if (result?.statusCode === 200) {
  //         cleanInputBox();
  //         getSTDMTerminologyData();
  //       }
  //     }
  //   };

  const handleFormatChange = (format) => {
    setSelectFormat(format);
    setUploadFile(null);
  };
  return (
    <>
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">
          Format <span className="text-danger">*</span>
        </Form.Label>
        <Form.Select
          aria-label="Default select example"
          onChange={(e) => handleFormatChange(e.target.value)}
          required
        >
          <option value="">Select Format</option>
          <option selected={selectFormat === "csv"} value="csv">
            CSV
          </option>
          <option selected={selectFormat === "excel"} value="excel">
            Excel
          </option>
        </Form.Select>
      </Form.Group>
      {selectFormat && (
        <>
          <Form.Group className="mb-2">
            <Form.Label className="mb-1 fw-bold">
              Upload {selectFormat.toUpperCase()} File{" "}
              <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="file"
              onChange={handleFileChange}
              accept={selectFormat === "csv" ? ".csv" : ".xls, .xlsx"}
              required
            />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label className="mb-1 fw-bold">
              Column Name <span className="text-danger">*</span>
            </Form.Label>
            <Form.Select onChange={(e) => setFileColumnName(e.target.value)}>
              <option value="">Select Column Name</option>
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label className="mb-1 fw-bold">
              Column Label <span className="text-danger">*</span>
            </Form.Label>
            <Form.Select onChange={(e) => setFileColumnLabel(e.target.value)}>
              <option value="">Select Column Label</option>
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label className="mb-1 fw-bold">
              Dataset Name <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter File Dataset Name"
              onChange={(e) => {
                setFileDatasetName(e.target.value);
              }}
              value={fileDatasetName}
              required
            />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label className="mb-1 fw-bold">
              Dataset Label <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter File Dataset Label"
              onChange={(e) => {
                setFileDatasetLabel(e.target.value);
              }}
              value={fileDatasetLabel}
              required
            />
          </Form.Group>
        </>
      )}

      {/* <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">
          Template <span className="text-danger">*</span>
        </Form.Label>
        <Form.Select
          aria-label="Default select example"
          onChange={(e) => handleFormatChange(e.target.value)}
          required
        >
          <option value="">Select Template</option>
          <option value="1">1</option>
          <option value="2">2</option>
        </Form.Select>
      </Form.Group> */}

      <Form.Group className="text-end">
        <button
          className="p-1 px-3 border rounded-1 text-white bg-color fs-13"
          disabled={!fileDatasetName || fileDatasetLabel}
        >
          <LuImport style={{ marginBottom: 2 }} /> Import
        </button>
      </Form.Group>
    </>
  );
};

export default ImportLegacy;
