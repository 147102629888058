import React, { useState } from "react";
import { Pagination, Table } from "react-bootstrap";
// import { cdiscServices } from "../../../../APIServices/cdiscServices";
// import Swal from "sweetalert2";

const   LegacyTableData = ({
  stdmDataSets,
  tableRef,
  onMouseDown,
  setIsOpenProperty,
  // getCDISCDatasets,
}) => {
  const [highlightedRow, setHighlightedRow] = useState(null);
  // const ref = useRef(null);
  // const deleteStudyDataByDomainId = async (id) => {
  //   Swal.fire({
  //     title: "Are you sure?",
  //     text: "You want to delete Datasets!",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#103C5E",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Yes, delete it!",
  //   }).then(async (result) => {
  //     if (result.isConfirmed) {
  //       let results = await cdiscServices.deleteStudyDataByDomainId(id);
  //       if (results?.statusCode === 200) {
  //         getCDISCDatasets();
  //       }
  //     }
  //   });
  // };

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = stdmDataSets?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(stdmDataSets?.length / itemsPerPage);
  return (
    <>
      <div className="overflow-auto">
        <Table
          tableRef={tableRef}
          id="resizable-table"
          className="text-nowrap custom-table"
          hover
          bordered
          striped
        >
          <thead className="position-sticky z-1" style={{ top: -1 }}>
            <tr>
              <th onMouseDown={(e) => onMouseDown(e, 1)} style={{ width: 40 }}>
                Sr.
              </th>
              <th onMouseDown={(e) => onMouseDown(e, 2)}>Dataset Name</th>
              <th onMouseDown={(e) => onMouseDown(e, 3)}>Dataset Label</th>
              <th onMouseDown={(e) => onMouseDown(e, 4)}>Import Date | Time</th>
              <th onMouseDown={(e) => onMouseDown(e, 5)} style={{ width: 60 }}>
                <div className="text-center">Delete</div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              className={`position-relative cursor-pointer ${
                highlightedRow  && "activeMenu"
              }`}
              onClick={() => setHighlightedRow()}
            >
              <td>1</td>
              <td onClick={() => setIsOpenProperty(true)}>120</td>
              <td>120</td>
              <td>12</td>
              <td>
                <div
                  className="text-center cursor-pointer"
                  title="Delete Supplementary"
                >
                  <i className={`fa-solid fa-trash-can text-danger`}></i>
                </div>
              </td>
            </tr>
            {/* {result?.map((item, index) => (
              <tr key={index} className="position-relative CP">
                <td>{(pageNumber - 1) * itemsPerPage + index + 1}</td>
                <td>{item?.dataset}</td>
                <td>{item?.keyVariables}</td>
                <td>{item?.comments}</td>
                <td>
                  <div
                    className="text-center cursor-pointer"
                    title="Delete Supplementary"
                    onClick={() => deleteStudyDataByDomainId(item?._id)}
                  >
                    <i className={`fa-solid fa-trash-can text-danger`}></i>
                  </div>
                </td>
              </tr>
            ))} */}
          </tbody>
        </Table>
      </div>
      <div className="position-sticky bottom-0">
        <Pagination
          totalPage={totalPage}
          pageNumber={pageNumber}
          itemsPerPage={itemsPerPage}
          totalItems={stdmDataSets?.length}
          setPageNumber={setPageNumber}
          setItemsPerPage={setItemsPerPage}
        />
      </div>
    </>
  );
};

export default LegacyTableData;
