import React, { useState } from "react";
import ClientStudyBookMark from "./ClientStudyBookMark";
import ClientDashboardLoginHistory from "./ClientDashboardLoginHistory";

const UserDashboardStudybookmark = ({ tableRef, onMouseDown }) => {
  const [activeTabs, setActiveTabs] = useState("study Bookmark");

  return (
    <>
      <div className={`bg-light`}>
        <div className="hstack me-1 gap-1">
          {["study Bookmark", "login History"].map((tab) => (
            <button
              key={tab}
              className={`buttonForTabs ${activeTabs === tab ? "activee" : ""}`}
              onClick={() => setActiveTabs(tab)}
              title={tab.charAt(0).toUpperCase() + tab.slice(1)}
            >
              {tab.charAt(0).toUpperCase() + tab.slice(1)}
            </button>
          ))}
        </div>
        {activeTabs === "study Bookmark" ? (
          <ClientStudyBookMark tableRef={tableRef} onMouseDown={onMouseDown} />
        ) : activeTabs === "login History" ? (
          <ClientDashboardLoginHistory
            tableRef={tableRef}
            onMouseDown={onMouseDown}
          />
        ) : (
          " "
        )}
      </div>
    </>
  );
};

export default UserDashboardStudybookmark;
